//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server } from "@/services/constants";
import StockCard from "@/components/cards/StockCard";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import { imageUrl } from "@/services/constants";
import {
  tolocalestringnumber,
  stringcommatonumber,
} from "../computing/tolocalestringnumber";

export default {
  name: "NewAdvance",
  data() {
    return {
      dialog: false,
      customer_code: "",
      addIteminv_h: {
        inv_id: "",
        pinv_id: "",
        company_id: localStorage.getItem(server.COMPANYID),
        inv_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        period_cover: "",
        inv_type: "C",
        customerid: 0,
        customer_address: "",
        customer_taxid: "",
        customer_branch: "",
        customer_paymentterm: 0,
        customer_vat: 0,
        so_total: 0,
        ts_total: 0,
        ad_total: 0,
        mis_total: 0,
        sub_total: 0,
        wth_rate: 0,
        tax_rate: 0,
        amount_total: 0,
        status: 0,
        user_create: localStorage.getItem(server.USER_ID),
      },
      showinv_no: "",
      showamount: 0,
      menu: false,
      menu2: false,
      os_h: {
        saleorder_id: "",
        customerid: 0,
        remark: "",
        po_no: "",
        po_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        delivery_date: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        additional: "",
        status: 0,
        image: "",
      },
      addinv_docref_cn: {
        inv_hid: 0,
        ref_type: "CN",
        ref_id: 0,
      },
      dialogDeleteimage: false,

      desserts: [
        {
          no: "1",
          detail: "",
          withdrawamount: 0,
        },
      ],
      headers: [
        {
          text: "No.",
          value: "no",
          class: "bg-colorth texttablehcenter ",
          width: "8%",
          align: "start",
          divider: true,
          sortable: false,
        },
        {
          text: "Service/Description",
          align: "center",
          value: "description",
          class: "bg-colorth texttablehcenter",
          width: "35%",
          align: "start",
          divider: true,
          sortable: false,
        },
        {
          text: "Qty",
          value: "unit",
          class: "bg-colorth texttablehcenter ",
          width: "15%",
          divider: true,
          align: "end",
          sortable: false,
        },

        {
          text: "Price Per Unit",
          value: "PricePerUnit",
          class: "bg-colorth texttablehcenter",
          width: "17%",
          divider: true,
          align: "end",
          sortable: false,
        },
        {
          text: "Amount",
          value: "amount",
          class: "bg-colorth texttablehcenter",
          width: "17%",
          divider: true,
          align: "end",
          sortable: false,
        },

        {
          value: "actions",
          class: "bg-colorth texttablehcenter",
          width: "8%",
          divider: true,
          sortable: false,
        },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        description: "",
        no: 0,
        unit: 0,
        amount: 0,
        PricePerUnit: 0,
      },
      defaultItem: {
        no: 0,
        description: "",
        amount: 0,
        unit: 0,
        PricePerUnit: 0,
      },
      advance_log: {
        module: "SO",
        module_id: 0,
        approve_id: 0,
        approve_status: 0,
        approve_date: null,
      },
      addinv_so: {
        inv_hid: 0,
        so_seq: 0,
        item_id: "",
        item_name: "",
        item_qty: 0,
        item_um: "",
        item_price: 0,
        item_disc: 0,
        item_line_amt: 0,
        user_create: localStorage.getItem(server.USER_ID),
      },
      lbl_date_request: false,
      dialogSignature: false,
      dialogSaveDraft: false,
      messageSaveDraft: "",
      paymentType: "",
      bankName: "",
      bankNumber: "",
      chequeNumber: "",
      remark: "",
      sendMail: {
        name: "",
        email: "",
        advance_id: "",
        link: "",
      },
      account: {
        signature: "",
      },
      customerNameList: [],
      customerList: [
        {
          cust_account: "",
        },
      ],
      cus_id: 0,
      datePO: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateDelivery: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dialogDelete: false,
      no_cus: 0,
      sumtotal: 0.0,
      vatCal: 7,
      vatCalSum: 0.0,
      vat: "7.00",
      sumGrandTotal: 0.0,
      preview_list: [],
      image_list: [],
      dialogAdd: false,
      dialogSendMail: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      customer: [],
    };
  },
  components: {
    StockCard,
    SuccessDialog,
    SuccessDialogPush,
  },
  computed: {
    version() {
      return this.initial_data;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDeletefrom();
    },
  },

  async mounted() {
    // this.$showLoader();
    this.$hideLoader();
    this.$forceUpdate();

    this.loadCustomer();

    // // alert(result.data[0])
    // console.log("testresult")
    //console.log(this.customerNameList)

    const userId = localStorage.getItem(server.USER_ID);
    if (userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }

    const result = await api.getAllCustomerListactiveCompany({
      params: {
        company_id: localStorage.getItem(server.COMPANYID),
      },
    });
    // console.log("result.data : ", JSON.stringify(result.data));
    this.customer = result.data;

    // const resultlist = await api.getCustomers();
    // this.customerNameList = result.data[0];
    this.customerNameList = result.data;
    await this.loaddatainv_h(this.$route.params.id);
  },
  methods: {
    async loaddatainv_h(param_id) {
      const res_h = await api.getdataByIdInvoice_H(param_id);
      console.log(res_h.data);
      this.customer_code = res_h.data[0].customer_id;
      this.cus_id = res_h.data[0].customer_id;
      this.showinv_no = res_h.data[0].inv_id;
      this.showamount = res_h.data[0].sub_total;
    },
    async loadCustomer() {
      const result = await api.getAllCustomerListactiveCompany({
        params: {
          company_id: localStorage.getItem(server.COMPANYID),
        },
      });
      // console.log("result.data : ", JSON.stringify(result.data));
      this.customer = result.data;

      // const resultlist = await api.getCustomers();
      // this.customerNameList = result.data[0];
      this.customerNameList = result.data;
    },
    save() {
      // alert("texttable")
      if (this.editedItem.description == "") {
        // alert("กรุณากรอก Description/Service ");
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "กรุณากรอก Description/Service!!!",
          "text-h5 red--text text-center"
        );
        this.$refs["txtDescription"].focus();
        return;
      }

      if (
        this.editedItem.unit == undefined ||
        this.editedItem.unit == "0" ||
        this.editedItem.unit == 0
      ) {
        // alert("กรุณากรอกจำนวน");
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "กรุณากรอกจำนวน Qty!!!",
          "text-h5 red--text text-center"
        );
        this.$refs["txtunit"].focus();
        return;
      }

      if (this.editedItem.unit < 0) {
        // alert("กรุณากรอกจำนวน");
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "จำนวน Qty ต้องมากกว่า 0!!!",
          "text-h5 red--text text-center"
        );
        this.$refs["txtunit"].focus();
        return;
      }

      if (
        this.editedItem.PricePerUnit == undefined ||
        this.editedItem.PricePerUnit == "0" ||
        this.editedItem.PricePerUnit == 0
      ) {
        // alert("กรุณากรอกจำนวนเงินต่อหน่วย");
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "กรุณากรอกจำนวน Price Per Unit!!!",
          "text-h5 red--text text-center"
        );
        this.$refs["txtPricePerUnit"].focus();
        return;
      }

      if (this.editedItem.PricePerUnit < 0) {
        // alert("กรุณากรอกจำนวน");
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "จำนวน Price Per Unit ต้องมากกว่า 0!!!",
          "text-h5 red--text text-center"
        );
        this.$refs["txtunit"].focus();
        return;
      }
      // if (this.editedItem.amount == 0 ) {
      //   alert("กรุณากรอกจำนวนเงิน");
      //   this.$refs["txtAmount"].focus();
      //   return;
      // }

      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.sumtotal = 0;
      this.sumGrandTotal = 0;
      // this.desserts.forEach((item,index) => {
      //     item.no = index+1;
      //     item.amount =  item.unit*item.PricePerUnit;
      //     this.sumtotal += parseFloat(item.amount);

      //  })
      this.desserts.forEach((item, index) => {
        item.no = index + 1;
        item.unit = stringcommatonumber(item.unit.toString());
        item.PricePerUnit = stringcommatonumber(item.PricePerUnit.toString());
        item.amount = parseFloat(item.unit * item.PricePerUnit).toFixed(2);
        this.sumtotal += parseFloat(item.amount, 10);
        item.unit = tolocalestringnumber(item.unit);
        item.PricePerUnit = tolocalestringnumber(item.PricePerUnit);
        item.amount = tolocalestringnumber(item.amount);
      });
      //console.log(this.desserts)
      this.sumtotal = this.sumtotal.toFixed(2);
      this.vatCalSum = (this.sumtotal * (this.vatCal / 100)).toFixed(2);
      this.sumGrandTotal = (this.sumtotal * (1 + this.vatCal / 100)).toFixed(2);
      // for(let i=0;i>=(this.desserts.length-1);i++){
      //   console.log("test: "+ this.desserts[i].no);
      // }
      // console.log("save : " + JSON.stringify(this.desserts));
      this.sumtotal = tolocalestringnumber(this.sumtotal);
      this.vatCalSum = tolocalestringnumber(this.vatCalSum);
      this.sumGrandTotal = tolocalestringnumber(this.sumGrandTotal);
      this.close();
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },

    async showCusName() {
      //alert(this.cus_id)

      const result = await api.getCustomersById(this.cus_id);
      // this.customerNameList = result.data[0];
      this.customerList = result.data;
      this.customer_code = this.cus_id;
      this.vatCal = parseInt(this.customerList[0].vat);
      this.vat = this.customerList[0].vat;
      // console.log(this.customerList)
    },
    async showCode() {
      //alert(this.customer_code)

      const result = await api.getCustomersById(this.customer_code);
      // this.customerNameList = result.data[0];
      this.customerList = result.data;
      this.cus_id = this.customer_code;
      this.vatCal = parseInt(this.customerList[0].vat);
      this.vat = this.customerList[0].vat;
    },
    async saveinvDraftandSubmit(mode) {
      console.log(this.addIteminv_h);

      if (
        !this.addIteminv_h.period_cover ||
        this.addIteminv_h.period_cover == ""
      ) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Reason CN Data",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (
        parseFloat(stringcommatonumber(this.sumtotal.toString())) >
        parseFloat(this.showamount)
      ) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "ไม่สามารถลดหนี้มากกว่าการตั้งหนี้ได้",
          "text-h5 red--text text-center"
        );
        return;
      }
      // return;
      this.$showLoader();
      let add_hresult = [];
      this.addIteminv_h.tax_rate = stringcommatonumber(this.vatCal.toString());
      this.addIteminv_h.sub_total = stringcommatonumber(this.sumtotal.toString());
      this.addIteminv_h.amount_total = stringcommatonumber(this.sumGrandTotal.toString());

      if (mode == "Draft") {
        this.addIteminv_h.status = 1;
        add_hresult = await api.addInvoice_HCN(this.addIteminv_h);
      } else if (mode == "Submit") {
        this.addIteminv_h.status = 2;
        add_hresult = await api.addInvoice_H_Geninv_idCN(this.addIteminv_h);
      } else {
      }
      console.log(this.desserts);

      if (add_hresult.status == 200 || add_hresult.status == 201) {
        this.desserts.forEach(async (x, index) => {
          this.addinv_so.inv_hid = add_hresult.data.id;
          this.addinv_so.so_seq = index + 1;
          this.addinv_so.item_name = x.description;
          this.addinv_so.item_qty = x.unit;
          this.addinv_so.item_price = stringcommatonumber(x.PricePerUnit.toString());
          this.addinv_so.item_line_amt = stringcommatonumber(x.amount.toString());
          await api.addInv_SO(this.addinv_so);
        });

        this.addinv_docref_cn.inv_hid = add_hresult.data.id;
        this.addinv_docref_cn.ref_id = this.$route.params.id;
        const add_docrefresult = await api.addInv_Docref(this.addinv_docref_cn);

        if (add_docrefresult.status == 200 || add_docrefresult.status == 201) {
          this.$hideLoader();
          if (mode == "Draft") {
            this.$store.state.global_push_dialog = true;
            this.setupAlertDialogPush(
              true,
              "Information Saved",
              `Information successfully saved id : ${add_hresult.data.id}`,
              "text-h5 green--text text-center",
              `/edit-credit-note/${add_hresult.data.id}`
            );

          } else {
            this.$store.state.global_push_dialog = true;
            this.setupAlertDialogPush(
              true,
              "Information Saved",
              `Information successfully saved`,
              "text-h5 green--text text-center",
              `/credit-note`
            );
          }
        }
      } else {
        this.$hideLoader();
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "ไม่สามารถบันทึกได้!!!",
          "text-h5 red--text text-center"
        );
      }
    },
    closeDeletefrom(){
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.sumtotal = 0;
      this.sumGrandTotal = 0;
      this.desserts.forEach((item, index) => {
        item.no = index + 1;
        this.sumtotal += Number(stringcommatonumber(item.amount));

      });
      this.vatCalSum = Number(this.sumtotal *(this.vatCal / 100));
      this.sumGrandTotal = Number(this.sumtotal * (1 + this.vatCal / 100));
      
      this.sumtotal = tolocalestringnumber(this.sumtotal )
      this.vatCalSum = tolocalestringnumber(this.vatCalSum)
      this.sumGrandTotal = tolocalestringnumber(this.sumGrandTotal)

      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },

    initialize() {},
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    previewImage: function(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    previewMultiImage: function(event) {
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview_list.push(e.target.result);
          };
          if (input.files[index].type.split("/")[0] === "image") {
            this.image_list.push(input.files[index]);
            reader.readAsDataURL(input.files[index]);
          }
          index++;
        }
      }
      console.log(this.image_list);
    },
    reset: function() {
      this.image = null;
      this.preview = null;
      this.image_list = [];
      this.preview_list = [];
    },
    clickremoveimage(index) {
      this.indeximage = index;
      this.dialogDeleteimage = true;
    },
    async removeimage(index) {
      this.preview_list.splice(this.indeximage, 1);
      this.image_list.splice(this.indeximage, 1);

      this.dialogDeleteimage = false;
    },
    closeDeleteimage() {
      this.indeximage = null;
      this.dialogDeleteimage = false;
    },
    closeSaveDraft() {},
  },
  async beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;

    // this.showdefaultsignature();
  },
  async created() {
    // this.showdefaultsignature();
  },
};
