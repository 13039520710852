import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

export const getLogInOutLog = () => {
  return httpClient.get(server.LOG_IN_OUT_LOG);
};


export const getLogInOutLogById = id => {
    return httpClient.get(server.LOG_IN_OUT_LOG + `/${id}`);
};
  
export const addLogInOutLog = data => {
    return httpClient.post(server.LOG_IN_OUT_LOG, data);
};
  
export const updateLogInOutLog = (id,data) => {
    return httpClient.put(server.LOG_IN_OUT_LOG + `/${id}`, data);
};

export const deleteLogInOutLog = id => {
    return httpClient.delete(server.LOG_IN_OUT_LOG + `/${id}`);
};

export const getLogInOutLogBySearch = (query) => {
    return httpClient.get(server.LOG_IN_OUT_LOG + `/search/data-all`, query);
};