import httpClient from "@/services/httpClient";
import {
  server
} from "@/services/constants";

export const getLeaveHolidayAll = (date) => {
    return httpClient.get(server.LEAVE_HOLIDAY + `/get`);
};
export const getLeaveHolidayByDate = (date) => {
    return httpClient.get(server.LEAVE_HOLIDAY + `/getbydate/${date}`);
};

export const createHoliday = (data) => {
  return httpClient.post(server.LEAVE_HOLIDAY, data);
};
export const importHoliday = (data) => {
  return httpClient.post(server.LEAVE_HOLIDAY +`/import`, data);
};

export const editHoliday = (id,data) => {
  return httpClient.put(server.LEAVE_HOLIDAY + `/${id}`, data);
};

export const deleteHoliday = (id,data) => {
  return httpClient.delete(server.LEAVE_HOLIDAY + `/${id}`, data);
};
  