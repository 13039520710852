import { render, staticRenderFns } from "./PrintReceiptDetailDejTab.vue?vue&type=template&id=4fd37684&scoped=true&"
import script from "./PrintReceiptDetailDejTab.vue?vue&type=script&lang=js&"
export * from "./PrintReceiptDetailDejTab.vue?vue&type=script&lang=js&"
import style0 from "./PrintReceiptDetailDejTab.vue?vue&type=style&index=0&id=4fd37684&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fd37684",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VDivider,VRadio,VRadioGroup,VRow})
