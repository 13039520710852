//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server } from "@/services/constants";
import {
  tolocalestringnumber,
  stringcommatonumber,
} from "../computing/tolocalestringnumber";

export default {
  name: "InvoiceSalseorderTab",
  props: [
    "datashowAllDetail",
    "print_detail_header",
    "heighttabledetail",
    "currency_print",
    "check_cus_name",
    "textamount_total_en",
    "checklawoffice",
    "exchange_rate",
    "branch_cus",
    "payment_by_print",
    "bank_name_show",
    "bank_no_show",
    "currency_show",
  ],
  // async beforeUpdate() {
  //   if (this.datainv_h.customerid != 0) {
  //     await this.loaddataSO();
  //   } else {
  //   }
  // },
  async beforeMount() {
  
  },
  async mounted() {
    console.log(this.datashowAll)
    // this.$showLoader();
   
  },
  components: {
    SuccessDialog,
  },
  data() {
    return {
      vat_type_list:[{id:"I",name:"Include Vat"},{id:"E",name:"Exclude Vat"}],
      isdisableeditpriceperunit:true,
      inv_title_list: [],
      status_loadso: 0,
      editedIndex: -1,
      dataeditItem: {
        no: 0,
        description: "",
        unit: 0,
        item_um: "",
        PricePerUnit: 0,
        amount: 0,
      },
      dialogEdit: false,
      dialogDelete: false,
      isShowPassword: false,
      fullPage: true,
      customer: [],
      customer_list: [],
      customer_code: "",
      customer_id: "",
      customer_name: "",
      saleorder_id: "",
      currency: "",
      tax_id: "",
      branch: "",
      invoice_address: "",
      payment_term: 0,
      vat: 0,
      po_no: "",
      attention: "",
      contact_position: "",
      vat_type:"",
      vatCal: 0,
      desserts: [],
      editedItem: {
        code: "",
        description: "",
        amount: 0,
      },
      editedItemadd: {
        headercheckbox: false,
        inv_title: "",
        description: "",
        no: 0,
        unit: 0,
        amount: 0,
        PricePerUnit: 0,
      },
      defaultItem: {
        headercheckbox: false,
        inv_title: "",
        description: "",
        no: 0,
        unit: 0,
        amount: 0,
        PricePerUnit: 0,
      },
      // dialogAdd: false,
      dialogSendMail: false,
      // text_color: "text-h5 green--text text-center",
      // title: "green",
      // message: "green",
      headers: [
        {
          text: "No.",
          value: "no",
          class: "bg-colorth texttablehcenter ",
          width: "8%",
          align: "start",
          divider: true,
          sortable: false,
        },
        {
          text: "Service Details",
          value: "description",
          class: "bg-colorth texttablehcenter",
          width: "30%",
          align: "start",
          divider: true,
          sortable: false,
        },
        {
          text: "Qty",
          value: "unit",
          class: "bg-colorth texttablehcenter ",
          width: "15%",
          divider: true,
          align: "end",
          sortable: false,
        },
        // {
        //   text: "Unit",
        //   value: "item_um",
        //   class: "bg-colorth texttablehcenter ",
        //   width: "10%",
        //   divider: true,
        //   align: "end",
        //   sortable: false,
        // },
        {
          text: "Price Per Unit",
          value: "PricePerUnit",
          class: "bg-colorth texttablehcenter",
          width: "14%",
          divider: true,
          align: "end",
          sortable: false,
        },
        {
          text: "Total Price",
          value: "amount",
          class: "bg-colorth texttablehcenter",
          width: "15%",
          divider: true,
          align: "end",
          sortable: false,
        },

        {
          value: "actions",
          class: "bg-colorth texttablehcenter",
          align: "center",
          width: "13%",
          divider: true,
          sortable: false,
        },
      ],
      dialogAddSo: false,
      text_colorSo: "text-h5 green--text text-center",
      titleSo: "green",
      messageSo: "green",
    };
  },
  methods: {

    async loadCustomer() {
      let result = [];
      result = await api.getAllCustomerListactive();

      this.customer = result.data;

      result.data.forEach((item) => {
        this.customer_list.push(item.name);
      });
      this.$hideLoader();
    },

   
    async onSelectpayment() {
      this.$emit("inv_sotab_paymentcus", this.payment_term);
      // alert(this.payment_term)
    },
    async onSelectvat() {
      this.$emit("inv_sotab_vatcus", this.vat);
      // alert(this.vat)
    },
    async onSelectpono() {
      this.$emit("inv_sotab_ponocus", this.po_no);
      // alert(this.po_no)
    },
    async onSelectattention() {
      this.$emit("inv_sotab_attentioncus", this.attention);
    },
    async onSelectcontact_position() {
      this.$emit("inv_sotab_contact_positioncus", this.contact_position);
    },
    async onSelectvat_type() {
      this.$emit("inv_sotab_vat_type", this.vat_type);
    },

    setupAlertDialog(status, titleSo, messageSo, text_colorSo) {
      this.titleSo = titleSo;
      this.messageSo = messageSo;
      this.dialogAddSo = status;
      this.text_colorSo = text_colorSo;
    },
  },

  beforeCreate() {
    // console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
};
