//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server } from "@/services/constants";
import { language } from "../language/Leavelist.js";
import {
  tolocalestringnumber,
  stringcommatonumber,
} from "../computing/tolocalestringnumber";
import PrintInvoiceDejTab from "./PrintInvoiceDejTab";
import PrintInvoiceStandardTab from "./PrintInvoiceStandardTab";
var convert = require("decimal-number-to-words");
import html2pdf from "html2pdf.js";
import unity from "@/unity/unity";

export default {
  name: "invoice",
  data(vm) {
    return {
      getcompany_id: localStorage.getItem(server.COMPANYID),
      department_search: 0,
      company_search: 0,
      haveSO: false,
      haveAD: false,
      indexinvADMIS: false,
      search_status: "",
      showprint: "display:none;",
      inv_id: 0,
      dialogPrintOutData: "1",
      dialogPrintOut: false,
      dialogSelectTitleData: "1",
      dialogSelectTitle: false,
      menu: false,
      menu2: false,
      search: "",
      toggleEnable: false,
      selectstatus: "",
      dataAll: [],
      status: ["Draft", "P.invoice", "Invoice", "Cancel"],
      datefrom: null,
      dateto: null,
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      seticonsearchmore: { icon: "mdi mdi-chevron-down", action: false },
      setlanguage: language.en,
      defaultlanguage: "en",
      // regexlang: "th",
      chooselanguage: [
        { name: "en", flag: "gb" },
        { name: "th", flag: "th" },
      ],
      aa: "สวัสดี",
      dialogEdit: false,
      dialogDelete: false,
      mDataArray: [
        {
          id: "97",
          date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
          customer: "testcus",
          status: "Draft",
        },
      ],
      check_cus_name: false,
      position: [],
      approver: [],
      department: [],
      department_list: [],
      position_list: [],
      company: [],
      authorize: [],
      headers: [
        {
          text: "Invoice No.",
          value: "invoice_no",
          class: "bg-colorth tabletextwhite",
          width: "100px",
        },
        {
          text: "Date",
          value: "date_show",
          class: "bg-colorth tabletextwhite",
          width: "103px",
        },
        {
          text: "Customer ID",
          value: "customer_cust_account",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Customer Name",
          value: "customer_name",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Amount",
          value: "amount_show",
          class: "bg-colorth tabletextwhite",
          align: "end",
        },
        {
          text: "Currency",
          value: "currency",
          class: "bg-colorth tabletextwhite",
        },

        {
          text: "",
          value: "actions",
          class: "bg-colorth tabletextwhite",
          width: "185px",
        },
      ],
      company_list: [
        {
          company_name: "บริษัท เดชอุดม จำกัด",
          company_code: "D" 
        },
        {
          company_name: "สำนักงานทนายความ",
          company_code: "L" 
        }
      ],
      print_header_list: [
        {
          id: "1",
          com_title: "STATEMENT",
          com_name:"",
          inv_company_name_th:"",
          inv_company_address:"",
          inv_company_branch: "",
          inv_company_tel: "",
          inv_company_email: "",
          inv_tax_id: "",
          com_eng_1: "DEJ-UDOM & ASSOCIATES LTD.",
          com_eng_2: "ATTORNEYS AT LAW",
          com_thai: "บริษัท เดชอุดม แอนด์ แอสโซซิเอทส์ จำกัด",
          com_add_name: "CHARN ISSARA TOWER 1, 2nd FLOOR,",
          com_add_no: "942/69 RAMA IV ROAD,",
          com_add_city: "KWAENG SURIYAWONG, KHET BANGRAK,",
          com_add_country: "BANGKOK 10500, THAILAND",
          com_add_tel: "Tel : 0-2233-0055, 0-2267-5152",
          com_add_fax: "Fax : 0-2236-6681, 0-2233-0227",
          com_add_email: "E-MAIL : account@dejudom.com",
          com_add_tax_id: `TAX ID. No. 0105534015348/"HEAD OFFICE"`,
        },
        {
          id: "2",
          com_title: "STATEMENT",
          com_name:"",
          inv_company_name_th:"",
          inv_company_address:"",
          inv_company_branch: "",
          inv_company_tel: "",
          inv_company_email: "",
          inv_tax_id: "",
          com_eng_1: "DEJ-UDOM & ASSOCIATES",
          com_eng_2: "ATTORNEYS AT LAW",
          com_thai: "สำนักกฎหมาย เดชอุดม แอนด์ แอสโซซิเอทส์",
          com_add_name: "CHARN ISSARA TOWER 1, 2nd FLOOR,",
          com_add_no: "942/69 RAMA IV ROAD,",
          com_add_city: "KWAENG SURIYAWONG, KHET BANGRAK,",
          com_add_country: "BANGKOK 10500, THAILAND",
          com_add_tel: "Tel : 0-2233-0055, 0-2267-5152",
          com_add_fax: "Fax : 0-2236-6681, 0-2233-0227",
          com_add_email: "E-MAIL : account@dejudom.com",
          com_add_tax_id: `TAX ID. No. 0992000028227/"HEAD OFFICE"`,
        },
      ],
      print_header: {},
      print_table_detail: [],
      pageAll: 1,
      opensummarize: false,
      datashowAll: [],
      setdatatoshow: [],
      dataforshowprpo: [],
      getheightcontentpr: 0,
      opensummarizetimesheet: false,
      pageAlltimesheet: 1,
      setdatatoshowtimesheet: [],
      print_table_detailtimesheet: [],
      datashowAlltimesheet: [],
      print_detail_timekeeper: [],
      datashowAlltimekeeper: [],
      showcurrency: "",
      item_id: 0,
      itemcurrency: [],
      checklawoffice:false,
      heighttabledetail:"546px",
    };
  },
  async beforeCreate() {
  
    this.$store.state.navMenu = true;
  },
  async created() {
    // this.headers = language.en.headertable;
    // this.initialize();
  },
  beforeUpdate() {},
  components: {
    PrintInvoiceDejTab,
    PrintInvoiceStandardTab,
  },
  computed: {
    computeddatefromFormatted() {
      return unity.formatDate(this.datefrom);
    },
    computeddateToFormatted() {
      return unity.formatDate(this.dateto);
    },
    itemsForSelected() {
      if (this.search.length) {
        return this.dataSource.filter((item) => this.search.includes(item));
      }
      return this.dataSource;
    },
  },
  async mounted() {
    await api.checkVersion();
    this.userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);

    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed!!!",
        "Please Logout And Login Again!!!",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");

    const res_auth = await api.getAuthorize(this.userId, router_path);

    

    this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
    this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
    this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
    this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;

  
    // this.$router.back();

    if (!this.authorize_view) {
      this.$router.back();
    }

    // ----------------- Check Authorize ---------------------------

    let comp_id = localStorage.getItem(server.COMPANYID);

    this.userId = localStorage.getItem(server.USER_ID);
    if (this.userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
    await this.loaddataInvoice();
    await this.loadDepartmentByCompanyId();
  },
  methods: {
    async loadDepartmentByCompanyId() {
      let result = await api.getAllDepartment_By_CompanyID(localStorage.getItem(server.COMPANYID));
      this.department_list = result.data;
    },
    async exportToPDF(advance_id) {
      // this.$refs.html2Pdf.generatePdf();

      this.showprint = "";
      let element = document.getElementById("mydivhtmltobase");
      const opt = {
        margin: [0, 0, 0, 0],
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        // enableLinks: false,
        html2canvas: {
          scale: 2,
          dpi: 192,
          letterRendering: true,
          useCORS: true,
          // ignoreElements: (e) => {
          //   return e.classList.contains("cke_pagebreak") ||
          //     e.classList.contains("html2pdf__page-break")
          //     ? true
          //     : false;
          // },
        },
        jsPDF: {
          unit: "cm",
          format: "A4",
          orientation: "portrait",
          putOnlyUsedFonts: true,
          pagesplit: true,
        },
        // pagebreak: { mode: ["avoid-all"], after: ".cke_pagebreak" },
      };

      let abcd = await html2pdf()
        .from(element)
        .set(opt)
        .toPdf()
        .get("pdf")
        // .output("bloburl")
        .then(function(pdf) {
         
          const totalPages = pdf.internal.getNumberOfPages();
          pdf.deletePage(totalPages);
          return pdf.output("bloburl");
        });
    
      window.open(abcd);
      let blob = await fetch(abcd).then((r) => r.blob());
      this.showprint = "display:none;";

      return;
    },
    getPrintDialog(item_id) {
      this.inv_id = item_id;
      if(localStorage.getItem(server.COMPANYID) == "1" || localStorage.getItem(server.COMPANYID) == 1){
      this.dialogPrintOut = true;
    }else{
      this.$router.push(`/so-for-invoice/${localStorage.getItem(server.COMPANYID)}`);
    }
    },
    async tosoforinvoice() {
      let company_inv = "";
      if (this.dialogPrintOutData == "1") {
        company_inv = "D";
      } else if (this.dialogPrintOutData == "2") {
        company_inv = "L";
      } else {
      }
      this.$router.push(`/so-for-invoice/${company_inv}`);
    },
    async getselecttitle(item_id, itemcurrency) {
      if(localStorage.getItem(server.COMPANYID) == "1" || localStorage.getItem(server.COMPANYID) == 1){
        this.dialogSelectTitleData = "1";
      this.item_id = item_id;
      this.itemcurrency = itemcurrency;
      this.dialogSelectTitle = true;
    }else{
      this.item_id = item_id;
      this.itemcurrency = itemcurrency;
      await this.getPrintOutData();
    }
     
    },
    async toprintfromselecttitle() {
      if (this.dialogSelectTitleData == "1") {
        this.print_header_list[0].com_title = "STATEMENT";
        this.print_header_list[1].com_title = "STATEMENT";
      } else if (this.dialogSelectTitleData == "2") {
        this.print_header_list[0].com_title = "INVOICE";
        this.print_header_list[1].com_title = "INVOICE";
      } else if(this.dialogSelectTitleData == "3"){
        this.print_header_list[0].com_title = "Proforma Invoice";
        this.print_header_list[1].com_title = "Proforma Invoice";
      } else {
        this.print_header_list[0].com_title = "";
        this.print_header_list[1].com_title = "";
      }

      await this.getPrintOutData();
      this.dialogSelectTitle = false;
    },
    async getPrintOutData(item_id, itemcurrency) {
      this.inv_id = this.item_id;
      this.showcurrency = this.itemcurrency;
      // this.showprint = "";
      this.haveSO = false;
      this.haveAD = false;
      this.datashowAll = [];
      this.print_table_detail = [];
      this.print_table_detailtimesheet = [];
      this.print_detail_timekeeper = [];
      this.dialogPrintOut = false;
      // alert(`Print Out Invoice ID: ${this.inv_id}, ${this.dialogPrintOutData}`);
      // if (this.dialogPrintOutData == "1") {
      //   this.print_header = this.print_header_list[0];
      // } else if (this.dialogPrintOutData == "2") {
      //   this.print_header = this.print_header_list[1];
      // }
      
      this.$showLoader();

      await this.loadRef_TypeCNById();
      await this.loadInvSoData();
      // await this.loadMainInvSoData();
      // await this.loadInvADData();
      await this.loadInvDocRefData();
      await this.loadInvTSGetTimekeeper();
      await this.loadInvMISData();
      await this.checkcontent();
      await this.checkcontenttimesheet();
      await this.exportToPDF();
      // this.showprint = "display:none;";
      this.$hideLoader();
    },
    async loadRef_TypeCNById() {
      // const res_docref_so = await api.getRef_TypeCNById(this.inv_id);
      // console.log(res_docref_so.data[0]);
      const res_hresult = await api.getdataByIdInvoice_H(this.inv_id);
      if (res_hresult.data[0].company_inv == "D") {
        this.getcompany_id = 1;
        this.heighttabledetail = "546px";
        this.print_header = this.print_header_list[0];
        this.checklawoffice = false;
      } else if (res_hresult.data[0].company_inv == "L") {
        this.getcompany_id = 1;
        this.heighttabledetail = "576px";
        this.print_header = this.print_header_list[1];
        this.checklawoffice = true;
      }else{
        this.getcompany_id = res_hresult.data[0].company_inv;
        this.print_header.com_name = res_hresult.data[0].com_name;
        this.print_header.inv_company_address = res_hresult.data[0].inv_company_address;
        this.print_header.inv_company_branch = res_hresult.data[0].inv_company_branch;
        this.print_header.inv_company_tel = res_hresult.data[0].inv_company_tel;
        this.print_header.inv_company_email = res_hresult.data[0].inv_company_email;
        this.print_header.inv_tax_id = res_hresult.data[0].inv_tax_id;
        this.print_header.inv_company_name_th = res_hresult.data[0].inv_company_name_th;

        if(res_hresult.data[0].inv_type == 'I'){
          this.print_header.com_title = "INVOICE";
        }else if(res_hresult.data[0].inv_type == 'P'){
          this.print_header.com_title = "Proforma Invoice";
        }else{
          this.print_header.com_title = "";
        }
        
      //   if (this.dialogSelectTitleData == "1") {
      //   this.print_header.com_title = "STATEMENT";
      //   this.print_header.com_title = "STATEMENT";
      // } else if (this.dialogSelectTitleData == "2") {
      //   this.print_header.com_title = "INVOICE";
      //   this.print_header.com_title = "INVOICE";
      // } else if(this.dialogSelectTitleData == "3"){
      //   this.print_header.com_title = "Proforma Invoice";
      //   this.print_header.com_title = "Proforma Invoice";
      // } else {
      //   this.print_header.com_title = "";
      //   this.print_header.com_title = "";
      // }
      }

      this.print_header.docref = res_hresult.data[0].po_no;
      this.print_header.attention = res_hresult.data[0].attention;
      this.print_header.contact_position = res_hresult.data[0].contact_position;
      this.print_header.inv_id = res_hresult.data[0].inv_id
        ? res_hresult.data[0].inv_id
        : res_hresult.data[0].pinv_id;
      this.print_header.customer_name = res_hresult.data[0].customer_name;
      this.print_header.invoice_date = res_hresult.data[0].date_show;
      this.print_header.addressall = res_hresult.data[0].addressall;
      this.print_header.customer_address = res_hresult.data[0].customer_address;

      this.print_header.province_invoice = res_hresult.data[0].province_invoice;
      this.print_header.country_invoice = res_hresult.data[0].country_invoice;
      this.print_header.zipcode_invoice = res_hresult.data[0].zipcode_invoice;

      this.print_header.period_cover = res_hresult.data[0].period_cover;
      this.print_header.customer_contact_name =
        res_hresult.data[0].customer_contact_name;
      this.print_header.customer_taxid = res_hresult.data[0].customer_taxid;
      this.print_header.customer_branch = res_hresult.data[0].customer_branch;

      this.print_header.ts_total = parseFloat(res_hresult.data[0].ts_total);
      this.print_header.sub_total = parseFloat(res_hresult.data[0].sub_total);
      this.print_header.wth_rate = res_hresult.data[0].wth_rate;
      this.print_header.tax_rate = res_hresult.data[0].tax_rate;
      this.print_header.wth_rate_amount = parseFloat(
        (res_hresult.data[0].sub_total * res_hresult.data[0].wth_rate) / 100
      );
      this.print_header.tax_rate_amount = parseFloat(
        (res_hresult.data[0].sub_total * res_hresult.data[0].tax_rate) / 100
      );

      const checker_th = /([\u0E00-\u0E7F]+)/gmu;
      let matches = this.print_header.customer_name.match(checker_th);
      // const checker_th = /([\u0E00-\u0E7F]+)/gmu;
      if(matches){
          this.check_cus_name = false;
      }else{
          this.check_cus_name = true;
      }

      if(this.check_cus_name){
        
        this.textamount_total_en = convert.toWords(
          res_hresult.data[0].amount_total
        )

        let setcheckscratch =false;
        this.textamount_total_en = this.textamount_total_en.replace(/\b./g, function(m){ 
          if(m == ","){
            return ""; 
          }
          if(m == "-"){
            setcheckscratch = true
            return m; 
          }else{
            if(setcheckscratch){
              setcheckscratch = false
              return m; 
            }
            setcheckscratch = false
            return m.toUpperCase(); 
          }
        });
      }else{
        var result = "";

        if(this.showcurrency == "THB"){
          //start เปลี่ยนเป็นตัวอ่านไทย 
          // อาเรย์สำหรับแปลงตัวเลขเป็นข้อความไทย
            var thaiNumbers = ['', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า'];
            var thaiUnits = ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน'];

            // แยกตัวจำนวนเต็มและทศนิยมห
            res_hresult.data[0].amount_total = Math.round(res_hresult.data[0].amount_total * 100) / 100;
            var [integerPart, decimalPart] = res_hresult.data[0].amount_total.toString().split('.');
            
            // แปลงส่วนทศนิยมเป็นข้อความไทย (สตางค์)
            let  de_check = 0;
            if(decimalPart){
              if(decimalPart.split('').length == 1){
                if(Number(decimalPart) >= 10){
                  decimalPart = decimalPart;
                }else if(Number(decimalPart) >0){
                  decimalPart = Number(decimalPart) * 10;
                }else{
                  de_check = 1;
                }
              }
            }else{
              de_check = 1;
            }
            
            let decimalText = '';
            if(de_check == 0){
              decimalPart = String(decimalPart);

              
              var numArrays = decimalPart.split('').map(Number);
              numArrays.forEach((num, index) => {
                var units = thaiUnits[numArrays.length - index - 1];
                var digits = thaiNumbers[num];
                if (num !== 0) {
                  if (num === 1 && index === numArrays.length - 1) { // ตรวจสอบว่าเป็นตำแหน่งสุดท้าย
                    decimalText += 'เอ็ด' + units;
                  } else if (num === 2 && index === numArrays.length - 2 && numArrays.length >= 2) {
                    decimalText += 'ยี่' + units;
                  } else if (num === 1 && index === numArrays.length - 2 && numArrays.length >= 2) {
                    decimalText += units;
                  } else {
                    decimalText += digits + units;
                  }
                }
              });

              decimalText = decimalText + "สตางค์";
            }

            // แปลงส่วนจำนวนเต็มเป็นข้อความไทย
            let integerText = '';

            var numArray = integerPart.split('').map(Number);
            numArray.forEach((num, index) => {
              var unit = thaiUnits[numArray.length - index - 1];
              var digit = thaiNumbers[num];
              if (num !== 0) {
                  if (num === 1 && index === numArray.length - 1) { // ตรวจสอบว่าเป็นตำแหน่งสุดท้าย
                    integerText += 'เอ็ด' + unit;
                  } else if (num === 2 && index === numArray.length - 2 && numArray.length >= 2) {
                    integerText += 'ยี่' + unit;
                  } else if (num === 1 && index === numArray.length - 2 && numArray.length >= 2) {
                    integerText += unit;
                  } else {
                    integerText += digit + unit;
                  }
              }
            });

            integerText = integerText + "บาท";

        

            result = integerText + decimalText;
        
          
        }else{
            //start เปลี่ยนเป็นตัวอ่านไทย 
            // อาเรย์สำหรับแปลงตัวเลขเป็นข้อความไทย
            var thaiNumbers = ['', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า'];
            var thaiUnits = ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน'];

            // แยกตัวจำนวนเต็มและทศนิยมห
            res_hresult.data[0].amount_total = Math.round(res_hresult.data[0].amount_total * 100) / 100;
            var [integerPart, decimalPart] = res_hresult.data[0].amount_total.toString().split('.');

            // แปลงส่วนทศนิยมเป็นข้อความไทย (สตางค์)
          let decimalText = '';
          if (decimalPart) {
            decimalText = 'จุด' + decimalPart.split('').map(digit => thaiNumbers[digit]).join('');
          }
          
          // แปลงส่วนจำนวนเต็มเป็นข้อความไทย
          let integerText = '';
          let numArray = integerPart.split('').map(Number);
          numArray.forEach((num, index) => {
            let unit = thaiUnits[numArray.length - index - 1];
            let digit = thaiNumbers[num];
            if (num !== 0) {
              if (num === 1 && index === numArray.length - 1) { // ตรวจสอบว่าเป็นตำแหน่งสุดท้าย
                  integerText += 'เอ็ด' + unit;
                } else if (num === 2 && index === numArray.length - 2 && numArray.length >= 2) {
                  integerText += 'ยี่' + unit;
                } else if (num === 1 && index === numArray.length - 2 && numArray.length >= 2) {
                  integerText += unit;
                } else {
                  integerText += digit + unit;
                }
            }
          });

          // รวมข้อความที่ได้และคืนค่ากลับ
          result = integerText + decimalText;
          
        }

        this.textamount_total_en = result;
      }

      this.print_header.amount_total = parseFloat(
        res_hresult.data[0].amount_total
      );
      this.print_header.additional_top = res_hresult.data[0].additional_top;
      this.print_header.additional_bottom =
        res_hresult.data[0].additional_bottom;
    },
    async loadInvSoData() {
      var tmp_inv_data;
      const res_inv_so = await api.printInvSO(this.inv_id);
  

      tmp_inv_data = res_inv_so.data;
      // this.print_header.docref = tmp_inv_data[0].saleorder_id;
      if (tmp_inv_data.length > 0) {
        this.haveSO = true;
      }

      tmp_inv_data.forEach((inv_so, index) => {
        var data = {
          report_type: "SO",
          report_desc: "",
          report_amount: 0,
          first_item: 0,
          solastdata: "",
        };
        if (index == tmp_inv_data.length - 1) {
          data.solastdata = "SOlastindex";
        }
        // if (index == 0) {
        //   data.first_item = 1;
        // }

        data.report_desc = inv_so.report_desc;
        data.report_amount = parseFloat(inv_so.report_amount);
        this.print_table_detail.push(data);
      });

    
    },
    async loadMainInvSoData() {
      var tmp_inv_data;
      const res_docref_so = await api.getRef_TypeCNById(this.inv_id);
      const res_inv_so = await api.printInvSO(res_docref_so.data[0].ref_id);
   

      tmp_inv_data = res_inv_so.data;

      tmp_inv_data.forEach((inv_so, index) => {
        var data = {
          report_type: "SO",
          report_desc: "",
          report_amount: 0,
          first_item: 0,
          solastdata: "",
        };
        if (index == tmp_inv_data.length - 1) {
          data.solastdata = "SOlastindex";
        }
        // if (index == 0) {
        //   data.first_item = 1;
        // }
        data.report_desc = inv_so.report_desc;
        data.report_amount = parseFloat(inv_so.report_amount);
        this.print_table_detail.push(data);
      });
    },
    async loadInvADData() {
      var tmp_inv_data;
      // const res_docref_so = await api.getRef_TypeCNById(this.inv_id);
      // alert(this.inv_id);
      const res_docref_ad = await api.getRef_TypeADById(this.inv_id);
 
      if (res_docref_ad.data[0]) {
        const res_inv_mis = await api.printInvAD(res_docref_ad.data[0].ref_id);

        tmp_inv_data = res_inv_mis.data;

        tmp_inv_data.forEach((inv_mis, index) => {
          var data = {
            report_type: "AD",
            report_desc: "",
            report_amount: 0,
            first_item: 0,
          };
          if (index == 0) {
            data.first_item = 1;
          }
          data.report_desc = inv_mis.report_desc;
          data.report_amount = parseFloat(inv_mis.report_amount);
          this.print_table_detail.push(data);
        });
      }
    },
    async loadInvMISData() {
      var tmp_inv_data;
      // const res_docref_so = await api.getRef_TypeCNById(this.inv_id);
      const res_inv_mis = await api.printInvMIS(this.inv_id);
 
      tmp_inv_data = res_inv_mis.data;

      tmp_inv_data.forEach((inv_mis, index) => {
        var data = {
          report_type: "ADMIS",
          report_desc: "",
          report_amount: 0,
          first_item: 0,
        };

        if (index == 0 && this.haveAD == false) {
          data.first_item = 1;
        }
        data.report_desc = inv_mis.report_desc;
        data.report_amount = parseFloat(inv_mis.report_amount);
        this.print_table_detail.push(data);
      });
   
    },
    async loadInvDocRefData() {
      const res_inv_doc_ref = await api.printInvDocRef(this.inv_id);
      // console.log("print_inv_doc_ref:", JSON.stringify(res_inv_doc_ref.data));
      this.indexinvADMIS = true;

      for await (const inv_doc_ref of res_inv_doc_ref.data) {
        if (inv_doc_ref.ref_type == "AD") {
          await this.loadInvAdvanceDetailData(inv_doc_ref.ref_id);
        }

        if (inv_doc_ref.ref_type == "TS") {
          await this.loadInvTimeSheetDetailData(inv_doc_ref.ref_id);
        }
      }

    

      // this.dataforshowprpo = this.print_table_detail;

      // await this.checkcontent();
    },

    async loadInvTSGetTimekeeper() {
      this.datashowAlltimekeeper = [];
      if (this.print_detail_timekeeper.length <= 0) {
        return;
      }
      let data = {};
      // data.hid = this.print_detail_timekeeper;
      data.hid = [this.item_id];

      const resultc = await api.getAllInvTSBytimesheetCreeper_hid(data);
      this.datashowAlltimekeeper = resultc.data;
      if(this.datashowAlltimekeeper.length > 0){
      this.datashowAlltimekeeper.forEach((item) => {
        // item.tc_rateChargeHour_bath = (
        //   (Number(item.tc_actualchargehour) / 60) *
        //   Number(item.tc_rate)
        // ).toFixed(2);
        item.tc_rateChargeHour_bath = (
          Number(item.tc_hours)
        ).toFixed(2);
        item.tc_nocharge = Math.abs(
          (
            (Number(item.tc_actualchargehour) - Number(item.tc_hours)) /
            60
          ).toFixed(2)
        );
        item.tc_nochargeCal = (
          Math.abs(
            (Number(item.tc_actualchargehour) - Number(item.tc_hours)) / 60
          ) * Number(item.tc_rate)
        ).toFixed(2);

        //sum all
        this.sum_chargehour =
          this.sum_chargehour + Number(item.tc_actualchargehour / 60);
        this.sum_rateChargehour =
          this.sum_rateChargehour + Number(item.tc_rateChargeHour_bath);
        this.sum_noCharge_hour =
          this.sum_noCharge_hour + Number(item.tc_nocharge);
        this.sum_noCharge_bath =
          this.sum_noCharge_bath + Number(item.tc_nochargeCal);
        this.sum_grandTotal = this.sum_rateChargehour;

        // //show data in table
        item.tc_rate_show = tolocalestringnumber(item.tc_rate);
        // item.tc_actualchargehour_show = tolocalestringnumber(
        //   Number(item.tc_actualchargehour / 60).toFixed(2)
        // );
        item.tc_actualchargehour_show = tolocalestringnumber(
          Number(item.tc_actualchargehour).toFixed(2)
        );
        item.tc_rateChargeHour_bath_show = tolocalestringnumber(
          item.tc_rateChargeHour_bath
        );
        item.tc_nocharge_show = tolocalestringnumber(item.tc_nocharge);
        item.tc_nochargeCal_show = tolocalestringnumber(item.tc_nochargeCal);
      });
    }
    },

    async checkcontent() {
     
      this.datashowAll = [];
      // alert(data.length);
      this.setdatatoshow = [];
      let linedetailprpo = 24;
      let datainlineprpo = 67;
      let addnewbutget = 1;
      let getdata = [];
      let getnewdata = [];
      let hllinedetails = 0;
      this.pageAll = 1;
      let newhllinedetails = 0;
      let checkamount_morethen_zero = false;
      for (let i = 0; i < this.print_table_detail.length; i++) {
        let stringchecklength = "";
        let lengthofdataforshowprpo = this.print_table_detail[i].report_desc
          .length;

        let maiek = "่"; //ไม้เอก
        let maito = "้"; //ไม้โท
        let maitree = "๊"; //ไม้ตรี
        let maijattawa = "๋"; //ไม้จัตวา
        let vowelei = "ิ"; //สระอิ
        let vowelee = "ี"; //สระอี
        let voweleue = "ึ"; //สระอึ
        let voweleu = "ื"; //สระอือ
        let vowelu = "ุ"; //สระอุ
        let voweluu = "ู"; //สระอู
        let maihun = "ั"; //ไม้หันอากาศ
        let maiyamok = "็"; //ไม้เอก
        newhllinedetails = 0;
        getnewdata = [];
        this.print_table_detail[i].no = i + 1;

        if(this.print_table_detail[i].report_desc.split("\n").length-1 > 0){
          let dataarray = this.print_table_detail[i].report_desc.split("\n");
          hllinedetails += this.print_table_detail[i].report_desc.split("\n").length-1;
          let lengthindexinarray = 0;
          for(let i = 0;i < dataarray.length;i++){
            lengthindexinarray = 0;
            lengthindexinarray = dataarray[i].length;

          for (
          let j = 0;
          j < dataarray[i].length;
          j++
        ) {
          if (
            stringchecklength[j] == maiek ||
            stringchecklength[j] == maito ||
            stringchecklength[j] == maitree ||
            stringchecklength[j] == maijattawa ||
            stringchecklength[j] == vowelei ||
            stringchecklength[j] == vowelee ||
            stringchecklength[j] == voweleue ||
            stringchecklength[j] == voweleu ||
            stringchecklength[j] == vowelu ||
            stringchecklength[j] == voweluu ||
            stringchecklength[j] == maihun ||
            stringchecklength[j] == maiyamok
          ) {
            lengthindexinarray--;
          }
        }

        newhllinedetails = Math.ceil(lengthindexinarray / datainlineprpo);


      }
          
        }else{
        for (
          let j = 0;
          j < this.print_table_detail[i].report_desc.length;
          j++
        ) {
          if (
            stringchecklength[j] == maiek ||
            stringchecklength[j] == maito ||
            stringchecklength[j] == maitree ||
            stringchecklength[j] == maijattawa ||
            stringchecklength[j] == vowelei ||
            stringchecklength[j] == vowelee ||
            stringchecklength[j] == voweleue ||
            stringchecklength[j] == voweleu ||
            stringchecklength[j] == vowelu ||
            stringchecklength[j] == voweluu ||
            stringchecklength[j] == maihun ||
            stringchecklength[j] == maiyamok
          ) {
            lengthofdataforshowprpo--;
          }
        }
        newhllinedetails = Math.ceil(lengthofdataforshowprpo / datainlineprpo);
      }
    
        if (
          this.print_table_detail[i].first_item == 1 ||
          this.print_table_detail[i].solastdata == "SOlastindex" ||
          this.haveSO == false
        ) {
          newhllinedetails + 2;
        }
        if(i > 0){
        if (this.print_table_detail[i].report_amount > 0 && checkamount_morethen_zero == false && this.print_table_detail[i-1].report_amount == 0) {
          checkamount_morethen_zero = true;
          this.print_table_detail[i].amount_morethen_zero = "yes";
          newhllinedetails + 1;
        }
      }
        // newhllinedetails = Math.ceil(
        //   this.print_table_detail[i].NAME.length / datainlineprpo
        // );

        // newhllinedetails += addnewbutget;
        getnewdata.push(this.print_table_detail[i]);
     
        if (hllinedetails + newhllinedetails > linedetailprpo) {
        
          this.setdatatoshow[this.pageAll - 1] = getdata;
          this.pageAll++;

          hllinedetails = 0;
          getdata = [];
          getdata.push(this.print_table_detail[i]);
          hllinedetails += newhllinedetails;
          if (i == this.print_table_detail.length - 1) {
            this.setdatatoshow[this.pageAll - 1] = getdata;
            getdata = [];
          }
        } else {
          hllinedetails += newhllinedetails;

          getdata.push(this.print_table_detail[i]);

          if (i == this.print_table_detail.length - 1) {
            this.setdatatoshow[this.pageAll - 1] = getdata;
            getdata = [];
          }
        }
      }
      this.opensummarize = true;
   

      this.setdatatoshow.forEach((item, iitem) => {
      
        if (iitem == this.setdatatoshow.length - 1) {
          item[this.setdatatoshow[iitem].length - 1].lastdata = "lastindex";
        }

        this.datashowAll.push(item);
      });
     
    },

    async checkcontenttimesheet() {
     
      this.datashowAlltimesheet = [];
      // alert(data.length);
      this.setdatatoshowtimesheet = [];
      let linedetailprpo = 42;
      let datainlineprpo = 60;
      let addnewbutget = 1;
      let getdata = [];
      let getnewdata = [];
      let hllinedetails = 0;
      this.pageAlltimesheet = 1;
      let newhllinedetails = 0;
      for (let i = 0; i < this.print_table_detailtimesheet.length; i++) {
        let stringchecklength = "";
        let lengthofdataforshowprpo = this.print_table_detailtimesheet[i]
          .report_desc.length;

        let maiek = "่"; //ไม้เอก
        let maito = "้"; //ไม้โท
        let maitree = "๊"; //ไม้ตรี
        let maijattawa = "๋"; //ไม้จัตวา
        let vowelei = "ิ"; //สระอิ
        let vowelee = "ี"; //สระอี
        let voweleue = "ึ"; //สระอึ
        let voweleu = "ื"; //สระอือ
        let vowelu = "ุ"; //สระอุ
        let voweluu = "ู"; //สระอู
        let maihun = "ั"; //ไม้หันอากาศ
        let maiyamok = "็"; //ไม้เอก
        newhllinedetails = 0;
        getnewdata = [];
        this.print_table_detailtimesheet[i].no = i + 1;

        for (
          let j = 0;
          j < this.print_table_detailtimesheet[i].report_desc.length;
          j++
        ) {
          if (
            stringchecklength[j] == maiek ||
            stringchecklength[j] == maito ||
            stringchecklength[j] == maitree ||
            stringchecklength[j] == maijattawa ||
            stringchecklength[j] == vowelei ||
            stringchecklength[j] == vowelee ||
            stringchecklength[j] == voweleue ||
            stringchecklength[j] == voweleu ||
            stringchecklength[j] == vowelu ||
            stringchecklength[j] == voweluu ||
            stringchecklength[j] == maihun ||
            stringchecklength[j] == maiyamok
          ) {
            lengthofdataforshowprpo--;
          }
        }
        newhllinedetails = Math.ceil(lengthofdataforshowprpo / datainlineprpo);
      

        // newhllinedetails = Math.ceil(
        //   this.print_table_detailtimesheet[i].NAME.length / datainlineprpo
        // );

        // newhllinedetails += addnewbutget;
        getnewdata.push(this.print_table_detailtimesheet[i]);
     
        if (hllinedetails + newhllinedetails > linedetailprpo) {
      
          this.setdatatoshowtimesheet[this.pageAlltimesheet - 1] = getdata;
          this.pageAlltimesheet++;

          hllinedetails = 0;
          getdata = [];
          getdata.push(this.print_table_detailtimesheet[i]);
          hllinedetails += newhllinedetails;
          if (i == this.print_table_detailtimesheet.length - 1) {
            this.setdatatoshowtimesheet[this.pageAlltimesheet - 1] = getdata;
            getdata = [];
          }
        } else {
          hllinedetails += newhllinedetails;

          getdata.push(this.print_table_detailtimesheet[i]);

          if (i == this.print_table_detailtimesheet.length - 1) {
            this.setdatatoshowtimesheet[this.pageAlltimesheet - 1] = getdata;
            getdata = [];
          }
        }
      }
      this.opensummarizetimesheet = true;


      this.setdatatoshowtimesheet.forEach((item, iitem) => {
    
        if (iitem == this.setdatatoshowtimesheet.length - 1) {
          item[this.setdatatoshowtimesheet[iitem].length - 1].lastdata =
            "lastindex";
        }

        this.datashowAlltimesheet.push(item);
      });
    
    },

    async loadInvTimeSheetDetailData(ref_id) {
      var tmp_inv_data;
      this.print_detail_timekeeper.push(ref_id);
      const res_inv_time_sheet_detail = await api.printInvTS(ref_id);
    
      tmp_inv_data = res_inv_time_sheet_detail.data;

      tmp_inv_data.forEach((inv_time_sheet_detail) => {
        var data = {
          report_type: "TS",
          report_desc: "",
          report_amount: 0,
          report_date: "",
          lawyer: "",
        };

        data.report_desc = inv_time_sheet_detail.report_desc;
        data.report_amount = parseFloat(inv_time_sheet_detail.report_amount);
        data.report_date = inv_time_sheet_detail.report_date;
        data.lawyer = inv_time_sheet_detail.lawyer;
        this.print_table_detailtimesheet.push(data);
      });

   
    },
    async loadInvAdvanceDetailData(ref_id) {
      var tmp_inv_data;
      const res_inv_advance_detail = await api.printInvAD(ref_id);
     
      tmp_inv_data = res_inv_advance_detail.data;
      if (tmp_inv_data.length > 0) {
        this.haveAD = true;
      }

      tmp_inv_data.forEach((inv_advance_detail, index) => {
        var data = {
          report_type: "ADMIS",
          report_desc: "",
          report_amount: 0,
          first_item: 0,
        };
        if (this.indexinvADMIS == true && index == 0) {
          data.first_item = 1;
        }

        data.report_desc = inv_advance_detail.report_desc;
        data.report_amount = parseFloat(inv_advance_detail.report_amount);
        this.print_table_detail.push(data);
      });
      this.indexinvADMIS = false;

    
    },

    editItem() {},
    showsearchmore() {
      this.toggleEnable = !this.toggleEnable;
      this.seticonsearchmore.action = !this.seticonsearchmore.action;
      if (this.seticonsearchmore.action === true) {
        this.seticonsearchmore.icon = "mdi mdi-chevron-up";
      } else {
        this.seticonsearchmore.icon = "mdi mdi-chevron-down";
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}-${month - 1}-${day}`;
    },
    // finddatafromdate() {
    //   //alert(this.datefrom)
    //   this.mDataArray = this.dataAll.filter((item) => {
    //     if (this.search_status == "") {
    //       if (this.datefrom != null && this.dateto != null) {
    //         if (item.date >= this.datefrom && item.date <= this.dateto) {
    //           return item;
    //         }
    //       }
    //       if (this.datefrom == null && this.dateto == null) {
    //         return item;
    //       }
    //     } else {
    //       if (this.datefrom != null && this.dateto != null) {
    //         if (
    //           item.date >= this.datefrom &&
    //           item.date <= this.dateto &&
    //           item.status == this.search_status
    //         ) {
    //           return item;
    //         }
    //       }
    //       if (this.datefrom == null && this.dateto == null) {
    //         if (item.status == this.search_status) {
    //           return item;
    //         }
    //       }
    //     }
    //   });
    // },
    findChaege() {

    

      let data_s = [];
      data_s = this.dataAll;
      if(this.company_search != ""){
        // alert("com")
        data_s = data_s.filter((item) => {
            if (item.company_inv == this.company_search) {
              return item;
            }
        })
      }
      if(this.department_search != 0){
        // alert("dept")
        data_s = data_s.filter((item) => {
          if (item.dept_inv == this.department_search) {
              return item;
          }
        })
      }
      if(this.search_status != ""){
        // alert("status")
        data_s = data_s.filter((item) => {
          if (item.status == this.search_status){
              return item;
          }
        })
      }

      if (this.datefrom != null && this.dateto != null) {
        // alert("date")
        data_s = data_s.filter((item) => {
          if (item.date >= this.datefrom && item.date <= this.dateto) {
            return item;
          }
        })
      }


      if( 
        this.company_search == "" &&
        this.department_search == 0 &&
        this.search_status == "" &&
        this.datefrom == null &&
        this.dateto == null
      ){
        data_s = this.dataAll;
      }

      this.mDataArray = data_s;
    },
    onClicktonewinvoice(id) {
      let name = "inv_h";
      this.$router.push(`/edit-invoice/${id}/${name}/edit`);
    },
    async loaddataInvoice() {
      const result = await api.getdataInvoice_HBycompany({
        params: {
          company_id: localStorage.getItem(server.COMPANYID),
          department_id: localStorage.getItem(server.DEPARTMENT_ID),
        },
      });
      this.dataAll = result.data;
 
      this.mDataArray = result.data;
      this.mDataArray.forEach((item) => {
        if (item.inv_id) {
          item.invoice_no = item.inv_id;
        } else if (item.pinv_id) {
          item.invoice_no = item.pinv_id;
        } else {
          item.invoice_no = "####";
        }
        item.amount_show = tolocalestringnumber(item.inv_amount);
      });
   

      // this.$showLoader();
      this.$hideLoader();
    },
  },
};
