//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import api from "@/services/api";
  import { server } from "@/services/constants";
  import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
  import SuccessDialog from "@/components/cards/SuccessDialog";
  import unity from "@/unity/unity";

  export default {
    name: "MasterTradeMark",
    components: { SuccessDialog, SuccessDialogPush },
    data() {
      return {
        oper_id: 0,
        menu_h: false,
        h_name: "",
        date_old: "",
        date_h: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
        userId: 0,
        authorize: [],
        authorize_view: false,
        authorize_add: false,
        authorize_edit: false,
        authorize_del: false,
        authorize_id: 0,
        load_bg: false,
        contact_type: [
          { contact_typenumber: 1, contact_typename: "Owner" },
          { contact_typenumber: 2, contact_typename: "Agent" },
          { contact_typenumber: 3, contact_typename: "Associate" },
        ],
        dialogAdd: false,
        dialogAddData: false,
        dialogDelete: false,
        isShowPassword: false,
        fullPage: true,
        search: "",
        mDataArray: [],
        text_color: "text-h5 green--text text-center",
        title: "green",
        message: "green",
        link: "",
        itemdata: {
          contact_type: 0,
          mapping_cust_id:0,
          contact_name: "",
          contact_person: "",
          position: "",
          email: "",
          address: "",
          city: "",
          country: "",
          telephone: "",
          fax: "",
          status: "A",
        },
        edititemdata: {
          contact_type: 0,
          mapping_cust_id:0,
          contact_name: "",
          contact_person: "",
          position: "",
          email: "",
          address: "",
          city: "",
          country: "",
          telephone: "",
          fax: "",
        },
        contactid_del: 0,
        oparation: "",
        headers: [
          {
            text: "ID",
            value: "id",
            class: "bg-colorth",
            divider: true,
          },
          {
            text: "Name",
            value: "h_name",
            class: "bg-colorth",
            divider: true,
          },
          {
            text: "Date",
            value: "date_show",
            class: "bg-colorth",
            divider: true,
          },
          {
            text: "",
            value: "actions",
            class: "bg-colorth",
            divider: true,
            width: "120px",
          },
        ],
        customer_name_list: [],
      };
    },
    async mounted() {
      this.$showLoader();
      await api.checkVersion();
  
      // ----------------- Check Authorize ---------------------------
      this.userId = localStorage.getItem(server.USER_ID);
      let yourUrlString = window.location;
      // alert(yourUrlString);
  
      let parser = document.createElement("a");
      parser.href = yourUrlString;
  
      this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);
      // alert("authorize_id:" + this.authorize_id);
      if (this.authorize_id == null || this.authorize_id == 0) {
        // this.$router.push("/login");
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Authorize Failed!!!",
          "Please Logout And Login Again!!!",
          "text-h5 red--text text-center"
        );
        this.$router.back();
      }
  
      const router_path = parser.pathname.replace("/", "");
  
      const res_auth = await api.getAuthorize(this.userId, router_path);
  
      console.log("res_auth:" + JSON.stringify(res_auth.data));
  
      this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
      this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
      this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
      this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;
  
      console.log("res_auth:" + JSON.stringify(res_auth.data));
      console.log("authorize_view:" + this.authorize_view);
      console.log("authorize_add:" + this.authorize_add);
      console.log("authorize_edit:" + this.authorize_edit);
      console.log("authorize_del:" + this.authorize_del);
      // this.$router.back();
  
      if (!this.authorize_view) {
        this.$router.back();
      }
  
      // ----------------- Check Authorize ---------------------------
      await this.loadData();
      this.$hideLoader();
    },
    computed: {
        computedDateFromFormatted() {
            return unity.formatDate(this.date_h);
        },
    },

    methods: {
        async loadData(){
            const res_data = await api.getLeaveHolidayAll();
            console.log("res_data.data")
            console.log(res_data.data)
            this.mDataArray = res_data.data;
            this.mDataArray.forEach( (item) => {
                item.date_show = unity.formatDate(item.date);
            })
        },
        async openadd() {
            this.h_name = "";
            this.date_h = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
            this.dialogAddData = true;
            this.oparation = "create";
        },
        async closeaddItem() {
            this.dialogAddData = false;
            this.h_name = "";
            this.date_h = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
        },
        
        openeditItem(item) {

            this.oparation = "update";
            this.oper_id = item.id;
            this.date_old = item.date;
            this.h_name = item.h_name;
            this.date_h = item.date;

            this.dialogAddData = true;

        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.oper_id = item.id;
        },
        closeDelete() {
            this.dialogDelete = false;
        },
        async deleteItemConfirm() {
            this.$showLoader();
            // eslint-disable-next-line prettier/prettier, no-undef

            const delete_holiday = await api.deleteHoliday(this.oper_id)
            
            if (delete_holiday.status == 200 || delete_holiday.status == 201) {
                this.$store.state.global_push_dialog = true;
                this.setupAlertDialog(
                    true,
                    "ลบข้อมูล!!!",
                    "ลบข้อมูลสำเร็จ!!!",
                    "text-h5 green--text text-center"
                );
            } else {
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                    true,
                    "ลบข้อมูล!!!",
                    "ไม่สามารถลบได้!!!",
                    "text-h5 red--text text-center"
                );
            }

            this.dialogDelete = false;
            await this.loadData();
            this.$hideLoader();
        },
        async saveaddItem() {

            this.dialogAddData = false;

            if (this.h_name == " " || this.h_name == null) {
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                    true,
                    "Failed!!!",
                    "Please Select Holiday Name",
                    "text-h5 red--text text-center"
                );
                return;
            }

           
            
            if (this.oparation == "create") {

                const check_date = await api.getLeaveHolidayByDate(this.date_h)
                
                // console.log("check_date.data")
                // console.log(check_date.data)

                if(check_date.data.length > 0){
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                        true,
                        "Failed!!!",
                        "Please Check Date Holiday Duplicate",
                        "text-h5 red--text text-center"
                    );
                    return;
                }

                var data_add = {};

                data_add.h_name = this.h_name;
                data_add.date = this.date_h;


                this.$showLoader();

                const create_holiday = await api.createHoliday (data_add);

                if (create_holiday.status == 200 || create_holiday.status == 201) {
                    this.$store.state.global_push_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "บันทึกข้อมูล!!!",
                    "บันทึกข้อมูลสำเร็จ!!!",
                    "text-h5 green--text text-center"
                    );
                } else {
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "บันทึกข้อมูล!!!",
                    "ไม่สามารถบันทึกได้!!!",
                    "text-h5 red--text text-center"
                    );
                }
            } else {
                // console.log(this.date_old)
                // console.log(this.date_h)

                if( this.date_old != this.date_h){
                    const check_date = await api.getLeaveHolidayByDate(this.date_h)
                    
                    // console.log("check_date.data")
                    // console.log(check_date.data)

                    if(check_date.data.length > 0){
                        this.$store.state.global_dialog = true;
                        this.setupAlertDialog(
                            true,
                            "Failed!!!",
                            "Please Check Date Holiday Duplicate",
                            "text-h5 red--text text-center"
                        );
                        return;
                    }
                }
                
                var data_edit = {};

                data_edit.h_name = this.h_name;
                data_edit.date = this.date_h;

                console.log(this.oper_id)
                console.log(data_edit)

                this.$showLoader();

                const edit_holiday = await api.editHoliday (this.oper_id,data_edit);

                if (edit_holiday.status == 200 || edit_holiday.status == 201) {
                    this.$store.state.global_push_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "บันทึกข้อมูล!!!",
                    "บันทึกข้อมูลสำเร็จ!!!",
                    "text-h5 green--text text-center"
                    );
                } else {
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                    "บันทึกข้อมูล!!!",
                    "ไม่สามารถบันทึกได้!!!",
                    "text-h5 red--text text-center"
                    );
                }
            }
            //console.log(this.itemdata);
            await this.loadData();
            this.$hideLoader();
        },

        setupAlertDialogPush(status, title, message, text_color, link) {
            this.title = title;
            this.message = message;
            this.dialogAdd = status;
            this.text_color = text_color;
            this.link = link;
        },
        setupAlertDialog(status, title, message, text_color) {
            this.title = title;
            this.message = message;
            this.dialogAdd = status;
            this.text_color = text_color;
        },
    },
    beforeCreate() {
            this.$store.state.navMenu = true;
    },
};
