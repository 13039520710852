var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"pa-16"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Immigration Transaction")])],1),_c('v-col',{attrs:{"align":"right","color":"primary","cols":"12","md":"6"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"to":"/immigration-transaction-add","color":"#3F7652","rounded":"","dark":"","disabled":!_vm.authorize_add}},[_c('span',[_vm._v("Add")]),_c('v-icon',{attrs:{"small":"","center":""}},[_vm._v("add")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}}),_c('v-col',{attrs:{"cols":"12","md":"1"}})],1)],1),_c('v-col',{staticClass:"d-flex justify-end mb-6",attrs:{"cols":"12","md":"4"}})],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.toggleEnable),expression:"toggleEnable"}]},[_c('v-col',{attrs:{"cols":"12","md":"6"}}),_c('v-col',{attrs:{"cols":"12","xl":"3","lg":"3","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"DateFrom","append-icon":"mdi-calendar","readonly":"","outlined":"","dense":"","clearable":""},on:{"click:clear":function($event){_vm.$nextTick(function () {
                  _vm.datefrom = null;
                  _vm.dateto = null;
                })}},model:{value:(_vm.computeddatefromFormatted),callback:function ($$v) {_vm.computeddatefromFormatted=$$v},expression:"computeddatefromFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.datefrom),callback:function ($$v) {_vm.datefrom=$$v},expression:"datefrom"}})],1)],1),_c('v-col',{attrs:{"cols":"12","xl":"3","lg":"3","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"DateTo","append-icon":"mdi-calendar","readonly":"","outlined":"","dense":"","clearable":""},on:{"click:clear":function($event){_vm.$nextTick(function () {
                  _vm.datefrom = null;
                  _vm.dateto = null;
                })}},model:{value:(_vm.computeddateToFormatted),callback:function ($$v) {_vm.computeddateToFormatted=$$v},expression:"computeddateToFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.dateto),callback:function ($$v) {_vm.dateto=$$v},expression:"dateto"}})],1)],1)],1),_c('br'),_c('v-card',{attrs:{"outlined":"","tile":""}},[_c('v-data-table',{staticClass:"elevation-1   595959",attrs:{"dense":"","search":_vm.search,"headers":_vm.headers,"items":_vm.mDataArray},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [(_vm.authorize_edit)?_c('v-icon',{staticClass:"ml-1",style:({
              backgroundColor: '#E1F5FE',
              color: '#2196F3',
            }),on:{"click":function($event){return _vm.openeditItem(item.id)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.authorize_del)?_c('v-icon',{staticClass:"ml-1",style:({
              backgroundColor: '#FFEBEE',
              color: '#F44336',
            }),on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Are you sure you want to delete this file?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1),_c('SuccessDialog',{attrs:{"status":_vm.dialogAdd,"text_color":_vm.text_color,"title":_vm.title,"message":_vm.message}}),_c('SuccessDialogPush',{attrs:{"status":_vm.dialogAdd,"text_color":_vm.text_color,"title":_vm.title,"message":_vm.message,"link":_vm.link}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }