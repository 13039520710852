//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server } from "@/services/constants";
import StockCard from "@/components/cards/StockCard";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import { imageUrl } from "@/services/constants";
import {
  tolocalestringnumber,
  stringcommatonumber,
} from "../computing/tolocalestringnumber";
import { language } from "../language/Leavelist.js";
import html2pdf from "html2pdf.js";
var convert = require("decimal-number-to-words");
import unity from "@/unity/unity";

export default {
  name: "NewAdvance",
  data() {
    return {
      dialog: false,
      customer_code: "",
      addIteminv_h: {
        inv_id: "",
        pinv_id: "",
        company_id: localStorage.getItem(server.COMPANYID),
        inv_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        period_cover: "",
        inv_type: "C",
        customerid: 0,
        customer_address: "",
        customer_taxid: "",
        customer_branch: "",
        customer_paymentterm: 0,
        customer_vat: 0,
        so_total: 0,
        ts_total: 0,
        ad_total: 0,
        mis_total: 0,
        sub_total: 0,
        wth_rate: 0,
        tax_rate: 0,
        amount_total: 0,
        status: 0,
        user_create: localStorage.getItem(server.USER_ID),
      },
      showinv_no: "",
      showinv_id: 0,
      showamount: 0,
      menu: false,
      menu2: false,
      os_h: {
        saleorder_id: "",
        customerid: 0,
        remark: "",
        po_no: "",
        po_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        delivery_date: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        additional: "",
        status: 0,
        image: "",
      },
      addinv_docref_cn: {
        inv_hid: 0,
        ref_type: "CN",
        ref_id: 0,
      },
      dialogDeleteimage: false,

      desserts: [
        {
          no: "1",
          detail: "",
          withdrawamount: 0,
        },
      ],
      headers: [
        {
          text: "No.",
          value: "no",
          class: "bg-colorth texttablehcenter ",
          width: "8%",
          align: "start",
          divider: true,
          sortable: false,
        },
        {
          text: "Service/Description",
          align: "center",
          value: "description",
          class: "bg-colorth texttablehcenter",
          width: "35%",
          align: "start",
          divider: true,
          sortable: false,
        },
        {
          text: "Qty",
          value: "unit",
          class: "bg-colorth texttablehcenter ",
          width: "15%",
          divider: true,
          align: "end",
          sortable: false,
        },

        {
          text: "Price Per Unit",
          value: "PricePerUnit",
          class: "bg-colorth texttablehcenter",
          width: "17%",
          divider: true,
          align: "end",
          sortable: false,
        },
        {
          text: "Amount",
          value: "amount",
          class: "bg-colorth texttablehcenter",
          width: "17%",
          divider: true,
          align: "end",
          sortable: false,
        },

        {
          value: "actions",
          class: "bg-colorth texttablehcenter",
          width: "8%",
          divider: true,
          sortable: false,
        },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        description: "",
        no: 0,
        unit: 0,
        amount: 0,
        PricePerUnit: 0,
      },
      defaultItem: {
        no: 0,
        description: "",
        amount: 0,
        unit: 0,
        PricePerUnit: 0,
      },
      advance_log: {
        module: "SO",
        module_id: 0,
        approve_id: 0,
        approve_status: 0,
        approve_date: null,
      },
      addinv_so: {
        inv_hid: 0,
        so_seq: 0,
        item_id: "",
        item_name: "",
        item_qty: 0,
        item_um: "",
        item_price: 0,
        item_disc: 0,
        item_line_amt: 0,
        user_create: localStorage.getItem(server.USER_ID),
      },
      lbl_date_request: false,
      dialogSignature: false,
      dialogSaveDraft: false,
      messageSaveDraft: "",
      currency_show: "",
      paymentType: "",
      bankName: "",
      bankNumber: "",
      chequeNumber: "",
      remark: "",
      sendMail: {
        name: "",
        email: "",
        advance_id: "",
        link: "",
      },
      account: {
        signature: "",
      },
      customerNameList: [],
      customerList: [
        {
          cust_account: "",
        },
      ],
      cus_id: 0,
      datePO: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateDelivery: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dialogDelete: false,
      no_cus: 0,
      sumtotal: 0.0,
      vatCal: 7,
      vatCalSum: 0.0,
      vat: "7.00",
      sumGrandTotal: 0.0,
      preview_list: [],
      image_list: [],
      dialogAdd: false,
      dialogSendMail: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      customer: [],

      //print va start
      showprint: "",
      textamount_total_en: "",
      dataformatpr: {
        headtitle: "Credit Note",
        title: "Dej-Udom & Associates LTD.",
        subtitle: "ATTORNEY AT LAW",
        paymenttype: "Payment Type",
        companyname_addressrow1:
          "เลขที่ 142 อาคาร ทู แปซิฟิค เพลส ชั้น20 ถนนสุขุมวิท แขวงคลองเตย",
        companyname_addressrow2: "เขตคลองเตย กรุงเทพฯ 10110",
        pr_number: "เลขที่ใบขอซื้อ/PR Number",
        pr_date: "วันที่/PR Date",
        companyname_tel: "โทรศัพท์/Tel. 02-6530489-9",
        companyname_fax: "โทรสาร/Fax. 02-6533706",
        prtitle_th: "ใบขอซื้อ",
        prtitle_en: "PURCHASE REQUISTION",
        titleth: "บริษัท เดชอุดม แอนด์ แอสโซซิเอทส์ จำกัด",
        address1: "CHARN ISSARA TOWER 9 FLOOR",
        address2: "942/69 RAMA IV ROAD,",
        address3: "KWAENG SURIYAWONG, KHET BANGRAK,",
        address4: "BANGKOK 10500, THAILAND",
        tel: "Tel : 0-2233-0055, 0-2267-5152",
        fax: "Fax : 0.2236-6681, 0-2233-0227",
        email: "E-MAIL:account@dejudom.com",
        taxid: 'TAX ID. No. 0992000028227 / "HEAD OFFICE"',
      },

      status: ["Draft", "P.Invoice", "Invoice", "Cancel"],
      datefrom: null,
      dateto: null,
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      seticonsearchmore: { icon: "mdi mdi-chevron-down", action: false },
      setlanguage: language.en,
      defaultlanguage: "en",
      // regexlang: "th",
      chooselanguage: [
        { name: "en", flag: "gb" },
        { name: "th", flag: "th" },
      ],
      aa: "สวัสดี",
      mDataArray: [
        {
          id: "97",
          date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
          customer: "testcus",
          status: "Draft",
        },
      ],
      position: [],
      approver: [],
      department: [],
      department_list: [],
      position_list: [],
      company: [],
      authorize: [],
      inv_id: 0,
      dialogPrintOutData: "1",
      print_header_list: [
        {
          id: "1",
          com_title: "Credit Note",
          com_eng_1: "DEJ-UDOM & ASSOCIATES LTD.",
          com_eng_2: "ATTORNEYS AT LAW",
          com_thai: "บริษัท เดชอุดม แอนด์ แอสโซซิเอทส์ จำกัด",
          com_add_name: "CHARN ISSARA TOWER 1, 2nd FLOOR,",
          com_add_no: "942/69 RAMA IV ROAD,",
          com_add_city: "KWAENG SURIYAWONG, KHET BANGRAK,",
          com_add_country: "BANGKOK 10500, THAILAND",
          com_add_tel: "Tel : 0-2233-0055, 0-2267-5152",
          com_add_email: "E-MAIL : account@dejudom.com",
          com_add_tax_id: `TAX I.D. No. 0992000028227/"HEAD OFFICE"`,
        },
        {
          id: "2",
          com_title: "Credit Note",
          com_eng_1: "DEJ-UDOM & ASSOCIATES",
          com_eng_2: "ATTORNEYS AT LAW",
          com_thai: "สำนักกฎหมาย เดชอุดม แอนด์ แอสโซซิเอทส์",
          com_add_name: "CHARN ISSARA TOWER 1, 2nd FLOOR,",
          com_add_no: "942/69 RAMA IV ROAD,",
          com_add_city: "KWAENG SURIYAWONG, KHET BANGRAK,",
          com_add_country: "BANGKOK 10500, THAILAND",
          com_add_tel: "Tel : 0-2233-0055, 0-2267-5152",
          com_add_email: "E-MAIL : account@dejudom.com",
          com_add_tax_id: `TAX I.D. No. 0992000028227`,
        },
      ],
      print_header: {},
      print_table_detail: [],
      pageAll: 1,
      opensummarize: false,
      datashowAll: [],
      setdatatoshow: [],
      dataforshowprpo: [],
      getheightcontentpr: 0,
      dialogPrintOutData: "1",
      dialogPrintOut: false,
      menu: false,
      menu2: false,
      search: "",
      toggleEnable: false,
      selectstatus: "",
      dataAll: [],
      setdatatoshow: [],
      dataforshowprpo: [],
      getheightcontentpr: 0,
      opensummarizetimesheet: false,
      pageAlltimesheet: 1,
      setdatatoshowtimesheet: [],
      print_table_detailtimesheet: [],
      datashowAlltimesheet: [],
      check_cus_name: false,
      //print va end
    };
  },
  components: {
    StockCard,
    SuccessDialog,
    SuccessDialogPush,
  },
  computed: {
    version() {
      return this.initial_data;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDeletefrom();
    },
  },

  async mounted() {
    // this.$showLoader();
    this.$hideLoader();
    this.$forceUpdate();

    this.loadCustomer();

    // // alert(result.data[0])
    // console.log("testresult")
    //console.log(this.customerNameList)

    const userId = localStorage.getItem(server.USER_ID);
    if (userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }

    const result = await api.getAllCustomerListactiveCompany({
      params: {
        company_id: localStorage.getItem(server.COMPANYID),
      },
    });
    // console.log("result.data : ", JSON.stringify(result.data));
    this.customer = result.data;

    // const resultlist = await api.getCustomers();
    // this.customerNameList = result.data[0];
    this.customerNameList = result.data;
    // await this.loaddatainv_h(this.$route.params.id);
    await this.loaddataEditCreditNote(this.$route.params.id);
  },
  methods: {
    //print start
    async loadRef_TypeCNById() {
      // const res_docref_so = await api.getRef_TypeCNById(this.inv_id);
      // console.log(res_docref_so.data[0]);
      const res_hresult = await api.getdataByIdInvoice_H(this.inv_id);
      console.log("loadRef_TypeCNById");
      console.log(res_hresult.data);

      if (res_hresult.data[0].company_inv == "D") {
        this.print_header = this.print_header_list[0];
      } else if (res_hresult.data[0].company_inv == "L") {
        this.print_header = this.print_header_list[1];
      }

      this.print_header.inv_id = res_hresult.data[0].inv_id
        ? res_hresult.data[0].inv_id
        : res_hresult.data[0].pinv_id;

      this.print_header.invoice_date = res_hresult.data[0].date_show;

      this.print_header.sub_total = parseFloat(res_hresult.data[0].sub_total);
      this.print_header.wth_rate = parseFloat(res_hresult.data[0].wth_rate);
      this.print_header.tax_rate = parseFloat(res_hresult.data[0].tax_rate);
      this.print_header.wth_rate_amount = parseFloat(
        (res_hresult.data[0].sub_total * res_hresult.data[0].wth_rate) / 100
      );
      this.print_header.tax_rate_amount = parseFloat(
        (res_hresult.data[0].sub_total * res_hresult.data[0].tax_rate) / 100
      );

      this.print_header.amount_total = parseFloat(res_hresult.data[0].amount_total);
      
      const checker_th = /([\u0E00-\u0E7F]+)/gmu;
      let matches = this.print_header.customer_name.match(checker_th);
      // const checker_th = /([\u0E00-\u0E7F]+)/gmu;
      if(matches){
          this.check_cus_name = false;
      }else{
          this.check_cus_name = true;
      }

      if(this.check_cus_name){

        this.textamount_total_en = convert.toWords(this.print_header.amount_total);
        let setcheckscratch =false;
        this.textamount_total_en = this.textamount_total_en.replace(/\b./g, function(m){ 
          if(m == ","){
            return ""; 
          }
          if(m == "-"){
            setcheckscratch = true
            return m; 
          }else{
            if(setcheckscratch){
              setcheckscratch = false
              return m; 
            }
            setcheckscratch = false
            return m.toUpperCase(); 
          }
        });

      }else{
        // console.log("test : flase")
          //start เปลี่ยนเป็นตัวอ่านไทย 
          // อาเรย์สำหรับแปลงตัวเลขเป็นข้อความไทย
          var thaiNumbers = ['', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า'];
          var thaiUnits = ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน'];

          // แยกตัวจำนวนเต็มและทศนิยมห
          this.print_header.amount_total = Math.round(this.print_header.amount_total * 100) / 100;
          var [integerPart, decimalPart] = this.print_header.amount_total.toString().split('.');
          
          let  de_check = 0;

          if(decimalPart){
            if(decimalPart.split('').length == 1){
              if(Number(decimalPart) >= 10){
                decimalPart = decimalPart;
              }else if(Number(decimalPart) >0){
                decimalPart = Number(decimalPart) * 10;
              }else{
                de_check = 1;
              }
            }
          }else{
            de_check = 1;
          }
          let decimalText = '';
          if(de_check == 0){
            decimalPart = String(decimalPart);

            
            let numArrays = decimalPart.split('').map(Number);
            numArrays.forEach((num, index) => {
              let units = thaiUnits[numArrays.length - index - 1];
              let digits = thaiNumbers[num];
              if (num !== 0) {
                if (num === 1 && index === numArrays.length - 1) { // ตรวจสอบว่าเป็นตำแหน่งสุดท้าย
                  decimalText += 'เอ็ด' + units;
                } else if (num === 2 && index === numArrays.length - 2 && numArrays.length >= 2) {
                  decimalText += 'ยี่' + units;
                } else if (num === 1 && index === numArrays.length - 2 && numArrays.length >= 2) {
                  decimalText += units;
                } else {
                  decimalText += digits + units;
                }
              }
            });

            decimalText = decimalText + "สตางค์";
          }

          // แปลงส่วนจำนวนเต็มเป็นข้อความไทย
          let integerText = '';

          let numArray = integerPart.split('').map(Number);
          numArray.forEach((num, index) => {
            let unit = thaiUnits[numArray.length - index - 1];
            let digit = thaiNumbers[num];
            if (num !== 0) {
                if (num === 1 && index === numArray.length - 1) { // ตรวจสอบว่าเป็นตำแหน่งสุดท้าย
                  integerText += 'เอ็ด' + unit;
                } else if (num === 2 && index === numArray.length - 2 && numArray.length >= 2) {
                  integerText += 'ยี่' + unit;
                } else if (num === 1 && index === numArray.length - 2 && numArray.length >= 2) {
                  integerText += unit;
                } else {
                  integerText += digit + unit;
                }
            }
          });

          integerText = integerText + "บาท";

          console.log("decimalText: " + decimalText)
          console.log("integerText: " + integerText)

          let result = "";
          result = integerText + decimalText;

          console.log("result convert:" +  result);
          this.textamount_total_en = result;
      }
    },
    async loadInvSoData() {
      var tmp_inv_data;
      const res_inv_so = await api.printInvSO(this.inv_id);
      // console.log("loadInvSoData");
      // console.log(res_inv_so);

      tmp_inv_data = res_inv_so.data;

      tmp_inv_data.forEach((inv_so, index) => {
        var data = {
          report_type: "SO",
          report_desc: "",
          report_amount: 0,
          first_item: 0,
        };
        if (index == 0) {
          data.first_item = 1;
        }

        data.report_desc = inv_so.report_desc;
        data.report_amount = parseFloat(inv_so.report_amount);
        this.print_table_detail.push(data);
      });
    },
    async loadMainInvSoData() {
      // var tmp_inv_data;
      const res_docref_so = await api.getRef_TypeCNById(this.inv_id);

      const res_inv_so = await api.getdataByIdInvoice_H(
        res_docref_so.data[0].ref_id
      );
      // console.log("res_inv_so.data");
      // console.log(res_inv_so.data);
      if (res_inv_so.data[0].company_inv == "D") {
        this.print_header = this.print_header_list[0];
      } else if (res_inv_so.data[0].company_inv == "L") {
        this.print_header = this.print_header_list[1];
      }
      this.currency_show =  res_inv_so.data[0].currency;
      this.print_header.customer_name = res_inv_so.data[0].customer_name;
      // this.print_header.invoice_date = res_inv_so.data[0].date;
      this.print_header.addressall = res_inv_so.data[0].addressall;
      this.print_header.customer_address = res_inv_so.data[0].customer_address;

      this.print_header.province_invoice = res_inv_so.data[0].province_invoice;
      this.print_header.country_invoice = res_inv_so.data[0].country_invoice;
      this.print_header.zipcode_invoice = res_inv_so.data[0].zipcode_invoice;

      this.print_header.period_cover = res_inv_so.data[0].inv_id
        ? res_inv_so.data[0].inv_id
        : res_inv_so.data[0].pinv_id;
      this.print_header.customer_contact_name =
        res_inv_so.data[0].customer_contact_name;
      this.print_header.customer_taxid = res_inv_so.data[0].customer_taxid;

    },
    async checkcontent() {
      console.log("send data ====");
      console.log(this.print_table_detail);
      this.datashowAll = [];
      // alert(data.length);
      this.setdatatoshow = [];
      let linedetailprpo = 23;
      let datainlineprpo = 80;
      let addnewbutget = 1;
      let getdata = [];
      let getnewdata = [];
      let hllinedetails = 0;
      this.pageAll = 1;
      let newhllinedetails = 0;
      for (let i = 0; i < this.print_table_detail.length; i++) {
        let stringchecklength = "";
        let lengthofdataforshowprpo = this.print_table_detail[i].report_desc
          .length;

        let maiek = "่"; //ไม้เอก
        let maito = "้"; //ไม้โท
        let maitree = "๊"; //ไม้ตรี
        let maijattawa = "๋"; //ไม้จัตวา
        let vowelei = "ิ"; //สระอิ
        let vowelee = "ี"; //สระอี
        let voweleue = "ึ"; //สระอึ
        let voweleu = "ื"; //สระอือ
        let vowelu = "ุ"; //สระอุ
        let voweluu = "ู"; //สระอู
        let maihun = "ั"; //ไม้หันอากาศ
        let maiyamok = "็"; //ไม้เอก
        newhllinedetails = 0;
        getnewdata = [];
        this.print_table_detail[i].no = i + 1;

        for (
          let j = 0;
          j < this.print_table_detail[i].report_desc.length;
          j++
        ) {
          if (
            stringchecklength[j] == maiek ||
            stringchecklength[j] == maito ||
            stringchecklength[j] == maitree ||
            stringchecklength[j] == maijattawa ||
            stringchecklength[j] == vowelei ||
            stringchecklength[j] == vowelee ||
            stringchecklength[j] == voweleue ||
            stringchecklength[j] == voweleu ||
            stringchecklength[j] == vowelu ||
            stringchecklength[j] == voweluu ||
            stringchecklength[j] == maihun ||
            stringchecklength[j] == maiyamok
          ) {
            lengthofdataforshowprpo--;
          }
        }
        newhllinedetails = Math.ceil(lengthofdataforshowprpo / datainlineprpo);
        console.log("data lll");
        console.log(newhllinedetails);
        // if (this.print_table_detail[i].first_item == 1) {
        //   newhllinedetails++;
        // }

        // newhllinedetails = Math.ceil(
        //   this.print_table_detail[i].NAME.length / datainlineprpo
        // );

        // newhllinedetails += addnewbutget;
        getnewdata.push(this.print_table_detail[i]);
        console.log(
          `${hllinedetails} +${newhllinedetails} +${linedetailprpo} +${i}`
        );
        if (hllinedetails + newhllinedetails > linedetailprpo) {
          console.log(i + " ============== " + this.print_table_detail.length);
          this.setdatatoshow[this.pageAll - 1] = getdata;
          this.pageAll++;

          hllinedetails = 0;
          getdata = [];
          getdata.push(this.print_table_detail[i]);
          hllinedetails += newhllinedetails;
          if (i == this.print_table_detail.length - 1) {
            this.setdatatoshow[this.pageAll - 1] = getdata;
            getdata = [];
          }
        } else {
          hllinedetails += newhllinedetails;

          getdata.push(this.print_table_detail[i]);

          if (i == this.print_table_detail.length - 1) {
            this.setdatatoshow[this.pageAll - 1] = getdata;
            getdata = [];
          }
        }
      }
      this.opensummarize = true;
      console.log(this.pageAll);
      console.log("showwwwwwwwwwwwwwwwwwwww");
      console.log(this.setdatatoshow);

      this.setdatatoshow.forEach((item, iitem) => {
        console.log(item);
        console.log(iitem + " " + this.setdatatoshow[0].length);
        if (iitem == this.setdatatoshow.length - 1) {
          item[this.setdatatoshow[iitem].length - 1].lastdata = "lastindex";
        }

        this.datashowAll.push(item);
      });
      console.log(this.datashowAll);
    },
    async exportToPDF(advance_id) {
      // this.$refs.html2Pdf.generatePdf();

      this.showprint = "";
      let element = document.getElementById("mydivhtmltobase");
      const opt = {
        margin: [0, 0, 0, 0],
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        // enableLinks: false,
        html2canvas: {
          scale: 2,
          dpi: 192,
          letterRendering: true,
          useCORS: true,
          // ignoreElements: (e) => {
          //   return e.classList.contains("cke_pagebreak") ||
          //     e.classList.contains("html2pdf__page-break")
          //     ? true
          //     : false;
          // },
        },
        jsPDF: {
          unit: "cm",
          format: "A4",
          orientation: "portrait",
          putOnlyUsedFonts: true,
          pagesplit: true,
        },
        // pagebreak: { mode: ["avoid-all"], after: ".cke_pagebreak" },
      };

      let abcd = await html2pdf()
        .from(element)
        .set(opt)
        .toPdf()
        .get("pdf")
        // .output("bloburl")
        .then(function(pdf) {
          console.log(pdf);
          const totalPages = pdf.internal.getNumberOfPages();
          pdf.deletePage(totalPages);
          return pdf.output("bloburl");
        });
      // console.log(abcd);
      window.open(abcd);
      let blob = await fetch(abcd).then((r) => r.blob());
      this.showprint = "display:none;";

      return;
    },
    async getPrintOutData() {
      this.inv_id = this.$route.params.id;
      this.showprint = "";
      this.datashowAll = [];
      this.print_table_detail = [];
      this.dialogPrintOut = false;
      this.$showLoader();
      // await this.loadRef_TypeCNById();
      await this.loadInvSoData();
      await this.loadMainInvSoData();
      await this.loadRef_TypeCNById();
      await this.checkcontent();
      await this.exportToPDF();
      console.log("data-test");
      console.log(this.print_header);
      this.$hideLoader();
    }, 
    
    // print end 

    
    async loadCustomer() {
      const result = await api.getAllCustomerListactiveCompany({
        params: {
          company_id: localStorage.getItem(server.COMPANYID),
        },
      });
      // console.log("result.data : ", JSON.stringify(result.data));
      this.customer = result.data;

      // const resultlist = await api.getCustomers();
      // this.customerNameList = result.data[0];
      this.customerNameList = result.data;
    },
    async loaddataEditCreditNote() {
      const res_hresult = await api.getdataByIdInvoice_H(this.$route.params.id);
      const res_docref_so = await api.getRef_TypeCNById(this.$route.params.id);
      const res_inv_so = await api.getdataByIdInv_SO(this.$route.params.id);
      this.desserts = res_inv_so.data;
      await this.loaddefaultvat();
      this.addIteminv_h.period_cover = res_hresult.data[0].period_cover;
      this.sumtotal = res_hresult.data[0].sub_total;
      // this.vatCalSum = res_hresult.data[0].tax_rate;
      this.sumGrandTotal = res_hresult.data[0].amount_total;

      this.showinv_id = res_docref_so.data[0].id_inv_h;
      this.showinv_no = res_docref_so.data[0].inv_id;
      this.showamount = res_docref_so.data[0].sub_total;
      this.customer_code = res_docref_so.data[0].customerid;
      this.cus_id = res_docref_so.data[0].customerid;
    },
    async loaddefaultvat() {
      this.desserts.forEach((item, index) => {
        item.no = index + 1;
        item.unit = stringcommatonumber(item.unit.toString());
        item.PricePerUnit = stringcommatonumber(item.PricePerUnit.toString());
        item.amount = parseFloat(item.unit * item.PricePerUnit).toFixed(2);
        this.sumtotal += parseFloat(item.amount, 10);
        item.unit = tolocalestringnumber(item.unit);
        item.PricePerUnit = tolocalestringnumber(item.PricePerUnit);
        item.amount = tolocalestringnumber(item.amount);
      });
      this.sumtotal = this.sumtotal.toFixed(2);
      this.vatCalSum = (this.sumtotal * (this.vatCal / 100)).toFixed(2);
      this.sumGrandTotal = (this.sumtotal * (1 + this.vatCal / 100)).toFixed(2);
      this.sumtotal = tolocalestringnumber(this.sumtotal);
      this.vatCalSum = tolocalestringnumber(this.vatCalSum);
      this.sumGrandTotal = tolocalestringnumber(this.sumGrandTotal);
    },
    save() {
      // alert("texttable")
      if (this.editedItem.description == "") {
        // alert("กรุณากรอก Description/Service ");
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "กรุณากรอก Description/Service!!!",
          "text-h5 red--text text-center"
        );
        this.$refs["txtDescription"].focus();
        return;
      }

      if (
        this.editedItem.unit == undefined ||
        this.editedItem.unit == "0" ||
        this.editedItem.unit == 0
      ) {
        // alert("กรุณากรอกจำนวน");
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "กรุณากรอกจำนวน Qty!!!",
          "text-h5 red--text text-center"
        );
        this.$refs["txtunit"].focus();
        return;
      }

      if (this.editedItem.unit < 0) {
        // alert("กรุณากรอกจำนวน");
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "จำนวน Qty ต้องมากกว่า 0!!!",
          "text-h5 red--text text-center"
        );
        this.$refs["txtunit"].focus();
        return;
      }

      if (
        this.editedItem.PricePerUnit == undefined ||
        this.editedItem.PricePerUnit == "0" ||
        this.editedItem.PricePerUnit == 0
      ) {
        // alert("กรุณากรอกจำนวนเงินต่อหน่วย");
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "กรุณากรอกจำนวน Price Per Unit!!!",
          "text-h5 red--text text-center"
        );
        this.$refs["txtPricePerUnit"].focus();
        return;
      }

      if (this.editedItem.PricePerUnit < 0) {
        // alert("กรุณากรอกจำนวน");
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "จำนวน Price Per Unit ต้องมากกว่า 0!!!",
          "text-h5 red--text text-center"
        );
        this.$refs["txtunit"].focus();
        return;
      }
      // if (this.editedItem.amount == 0 ) {
      //   alert("กรุณากรอกจำนวนเงิน");
      //   this.$refs["txtAmount"].focus();
      //   return;
      // }

      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.sumtotal = 0;
      this.sumGrandTotal = 0;
      // this.desserts.forEach((item,index) => {
      //     item.no = index+1;
      //     item.amount =  item.unit*item.PricePerUnit;
      //     this.sumtotal += parseFloat(item.amount);

      //  })
      this.desserts.forEach((item, index) => {
        item.no = index + 1;
        item.unit = stringcommatonumber(item.unit.toString());
        item.PricePerUnit = stringcommatonumber(item.PricePerUnit.toString());
        item.amount = parseFloat(item.unit * item.PricePerUnit).toFixed(2);
        this.sumtotal += parseFloat(item.amount, 10);
        item.unit = tolocalestringnumber(item.unit);
        item.PricePerUnit = tolocalestringnumber(item.PricePerUnit);
        item.amount = tolocalestringnumber(item.amount);
      });
      //console.log(this.desserts)
      this.sumtotal = this.sumtotal.toFixed(2);
      this.vatCalSum = (this.sumtotal * (this.vatCal / 100)).toFixed(2);
      this.sumGrandTotal = (this.sumtotal * (1 + this.vatCal / 100)).toFixed(2);
      // for(let i=0;i>=(this.desserts.length-1);i++){
      //   console.log("test: "+ this.desserts[i].no);
      // }
      // console.log("save : " + JSON.stringify(this.desserts));
      this.sumtotal = tolocalestringnumber(this.sumtotal);
      this.vatCalSum = tolocalestringnumber(this.vatCalSum);
      this.sumGrandTotal = tolocalestringnumber(this.sumGrandTotal);
      this.close();
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },

    async showCusName() {
      //alert(this.cus_id)

      const result = await api.getCustomersById(this.cus_id);
      // this.customerNameList = result.data[0];
      this.customerList = result.data;
      this.customer_code = this.cus_id;
      this.vatCal = parseInt(this.customerList[0].vat);
      this.vat = this.customerList[0].vat;
      // console.log(this.customerList)
    },
    async showCode() {
      //alert(this.customer_code)

      const result = await api.getCustomersById(this.customer_code);
      // this.customerNameList = result.data[0];
      this.customerList = result.data;
      this.cus_id = this.customer_code;
      this.vatCal = parseInt(this.customerList[0].vat);
      this.vat = this.customerList[0].vat;
    },
    async saveinvDraftandSubmit(mode) {
      console.log(this.addIteminv_h);

      if (
        !this.addIteminv_h.period_cover ||
        this.addIteminv_h.period_cover == ""
      ) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "Please enter Reason CN Data",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (
        parseFloat(stringcommatonumber(this.sumtotal.toString())) >
        parseFloat(this.showamount)
      ) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed!!!",
          "ไม่สามารถลดหนี้มากกว่าการตั้งหนี้ได้",
          "text-h5 red--text text-center"
        );
        return;
      }
      // return;
      this.$showLoader();
      let add_hresult = [];
      this.addIteminv_h.tax_rate = stringcommatonumber(this.vatCal.toString());
      this.addIteminv_h.sub_total = stringcommatonumber(this.sumtotal.toString());
      this.addIteminv_h.amount_total = stringcommatonumber(this.sumGrandTotal.toString());
      if (mode == "Draft") {
        this.addIteminv_h.status = 1;
        add_hresult = await api.updateInvoice_H(
          this.$route.params.id,
          this.addIteminv_h
        );
      } else if (mode == "Submit") {
        this.addIteminv_h.status = 2;
        add_hresult = await api.updateInvoice_H_Geninv_id(
          this.$route.params.id,
          this.addIteminv_h
        );
      } else {
      }
      console.log(this.desserts);

      if (add_hresult.status == 200 || add_hresult.status == 201) {
        await api.deleteInv_SO(this.$route.params.id);
        this.desserts.forEach(async (x, index) => {
          this.addinv_so.inv_hid = this.$route.params.id;
          this.addinv_so.so_seq = index + 1;
          this.addinv_so.item_name = x.description;
          this.addinv_so.item_qty = x.unit;
          this.addinv_so.item_price = stringcommatonumber(x.PricePerUnit.toString());
          this.addinv_so.item_line_amt = stringcommatonumber(x.amount.toString());
          await api.addInv_SO(this.addinv_so);
        });

        this.addinv_docref_cn.inv_hid = this.$route.params.id;
        this.addinv_docref_cn.ref_id = this.showinv_id;

        await api.delete_docrefCN(this.$route.params.id);
        const add_docrefresult = await api.addInv_Docref(this.addinv_docref_cn);

        if (add_docrefresult.status == 200 || add_docrefresult.status == 201) {
          this.$hideLoader();
          if (mode == "Submit") {
            this.$store.state.global_push_dialog = true;
            this.setupAlertDialogPush(
              true,
              "Information Saved",
              "Information successfully saved",
              "text-h5 green--text text-center",
              "/credit-note"
            );
          }else{
            this.$store.state.global_push_dialog = true;
            this.setupAlertDialogPush(
              true,
              "Information Saved",
              "Information successfully saved",
              "text-h5 green--text text-center",
              `/edit-credit-note/${this.$route.params.id}`
            );
          }

        }
      } else {
        this.$hideLoader();
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "ไม่สามารถบันทึกได้!!!",
          "text-h5 red--text text-center"
        );
      }
    },
    closeDeletefrom(){
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.sumtotal = 0;
      this.sumGrandTotal = 0;

      this.desserts.forEach((item, index) => {
        item.no = index + 1;
        this.sumtotal += Number(stringcommatonumber(item.amount));

      });
      this.vatCalSum = Number(this.sumtotal *(this.vatCal / 100));
      this.sumGrandTotal = Number(this.sumtotal * (1 + this.vatCal / 100));
      
      this.sumtotal = tolocalestringnumber(this.sumtotal )
      this.vatCalSum = tolocalestringnumber(this.vatCalSum)
      this.sumGrandTotal = tolocalestringnumber(this.sumGrandTotal)

      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },

    initialize() {},
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    previewImage: function(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    previewMultiImage: function(event) {
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview_list.push(e.target.result);
          };
          if (input.files[index].type.split("/")[0] === "image") {
            this.image_list.push(input.files[index]);
            reader.readAsDataURL(input.files[index]);
          }
          index++;
        }
      }
      console.log(this.image_list);
    },
    reset: function() {
      this.image = null;
      this.preview = null;
      this.image_list = [];
      this.preview_list = [];
    },
    clickremoveimage(index) {
      this.indeximage = index;
      this.dialogDeleteimage = true;
    },
    async removeimage(index) {
      this.preview_list.splice(this.indeximage, 1);
      this.image_list.splice(this.indeximage, 1);

      this.dialogDeleteimage = false;
    },
    closeDeleteimage() {
      this.indeximage = null;
      this.dialogDeleteimage = false;
    },
    closeSaveDraft() {},
  },
  async beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;

    // this.showdefaultsignature();
  },
  async created() {
    // this.showdefaultsignature();
  },
};
