import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";


export const getAllAdvanceCodeByCompany = (company_id) => {
  return httpClient.get(
    server.ADVANCE_CODE_URL + `/getAllByCompany/${company_id}`
  );
};

