//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogInvoiceFreetext_So from "@/components/cards/SuccessDialogInvoiceFreetext_So";
import api from "@/services/api";
import { server } from "@/services/constants";
import {
  tolocalestringnumber,
  stringcommatonumber,
} from "../computing/tolocalestringnumber";

export default {
  name: "InvoiceSalseorderTab",
  props: [
    "so_id",
    "datainv_h",
    "datainv_so",
    "datainv_disable",
    "vat_cus",
    "payment_term_cus",
    "po_no_cus",
    "attention_cus",
    "contact_position_cus",
    "vat_type_cus",
  ],
  // async beforeUpdate() {
  //   if (this.datainv_h.customerid != 0) {
  //     await this.loaddataSO();
  //   } else {
  //   }
  // },
  async beforeMount() {
    await this.loaddataSO();
    if (this.datainv_h.dept_type == "IM") {
      this.isdisableeditpriceperunit = false;
      await this.loadinv_titlelist();
    }
    if (this.datainv_h.dept_type == "IP") {
      this.isdisableeditpriceperunit = false;
      await this.loadip_pricelist();
    }
    if (this.datainv_h.dept_type == "FreeText") {
      this.isdisableeditpriceperunit = false;
    }
  },
  async mounted() {
    this.$showLoader();
    // await this.loaddataSO();
    // await this.loadCustomer();
    await this.loadCustomer();
    if (this.po_no_cus != "") {
      this.po_no = this.po_no_cus;
    }
    if (this.attention_cus != "") {
      this.attention = this.attention_cus;
    }
    if (this.contact_position_cus != "") {
      this.contact_position = this.contact_position_cus;
    }
    if (this.vat_type_cus != "") {
      this.vat_type = this.vat_type_cus;
    }
    if (this.vat_cus != 0) {
      this.vat = this.vat_cus;
    }
    if (this.payment_term_cus != 0) {
      this.payment_term = this.payment_term_cus;
    }
    if (this.status_loadso == 0) {
      this.vat_cus = this.datainv_h.customer_vat;
      this.payment_term_cus = this.datainv_h.customer_paymentterm;
      this.po_no_cus = this.datainv_h.po_no;
      this.attention_cus = this.datainv_h.attention;
      this.contact_position_cus = this.datainv_h.contact_position;
      this.vat_type_cus = this.datainv_h.vat_type;
      this.$emit("inv_sotab_paymentcus", this.payment_term);
      this.$emit("inv_sotab_vatcus", this.vat);
      this.$emit("inv_sotab_ponocus", this.po_no);
      this.$emit("inv_sotab_attentioncus", this.attention);
      this.$emit("inv_sotab_contact_positioncus", this.contact_position);
      this.$emit("inv_sotab_vat_type", this.vat_type);
      this.status_loadso = 1;
    }
    if (this.datainv_h.dept_type == "IM") {
      this.isdisableeditpriceperunit = false;
      await this.loadinv_titlelist();
    }
    if (this.datainv_h.dept_type == "IP") {
      this.isdisableeditpriceperunit = false;
      await this.loadip_pricelist();
    }
    if (this.datainv_h.dept_type == "FreeText") {
      this.isdisableeditpriceperunit = false;
    }
  },
  components: {
    SuccessDialog,
    SuccessDialogInvoiceFreetext_So,
  },
  data() {
    return {
      vat_type_list:[{id:"I",name:"Include Vat"},{id:"E",name:"Exclude Vat"}],
      isdisableeditpriceperunit:true,
      inv_title_list: [],
      status_loadso: 0,
      editedIndex: -1,
      dataeditItem: {
        no: 0,
        description: "",
        unit: 0,
        item_um: "",
        PricePerUnit: 0,
        amount: 0,
      },
      dialogEdit: false,
      dialogDelete: false,
      isShowPassword: false,
      fullPage: true,
      customer: [],
      customer_list: [],
      customer_code: "",
      customer_id: "",
      customer_name: "",
      saleorder_id: "",
      currency: "",
      tax_id: "",
      branch: "",
      invoice_address: "",
      payment_term: 0,
      vat: 0,
      po_no: "",
      attention: "",
      contact_position: "",
      vat_type:"",
      vatCal: 0,
      desserts: [],
      editedItem: {
        code: "",
        description: "",
        amount: 0,
      },
      editedItemadd: {
        headercheckbox: false,
        inv_title: "",
        description: "",
        no: 0,
        unit: 0,
        amount: 0,
        PricePerUnit: 0,
      },
      defaultItem: {
        headercheckbox: false,
        inv_title: "",
        description: "",
        no: 0,
        unit: 0,
        amount: 0,
        PricePerUnit: 0,
      },
      // dialogAdd: false,
      dialogSendMail: false,
      // text_color: "text-h5 green--text text-center",
      // title: "green",
      // message: "green",
      headers: [
        {
          text: "No.",
          value: "no",
          class: "bg-colorth texttablehcenter ",
          width: "8%",
          align: "start",
          divider: true,
          sortable: false,
        },
        {
          text: "Service Details",
          value: "description",
          class: "bg-colorth texttablehcenter",
          width: "30%",
          align: "start",
          divider: true,
          sortable: false,
        },
        {
          text: "Qty",
          value: "unit",
          class: "bg-colorth texttablehcenter ",
          width: "15%",
          divider: true,
          align: "end",
          sortable: false,
        },
        // {
        //   text: "Unit",
        //   value: "item_um",
        //   class: "bg-colorth texttablehcenter ",
        //   width: "10%",
        //   divider: true,
        //   align: "end",
        //   sortable: false,
        // },
        {
          text: "Price Per Unit",
          value: "PricePerUnit",
          class: "bg-colorth texttablehcenter",
          width: "14%",
          divider: true,
          align: "end",
          sortable: false,
        },
        {
          text: "Total Price",
          value: "amount",
          class: "bg-colorth texttablehcenter",
          width: "15%",
          divider: true,
          align: "end",
          sortable: false,
        },

        {
          value: "actions",
          class: "bg-colorth texttablehcenter",
          align: "center",
          width: "13%",
          divider: true,
          sortable: false,
        },
      ],
      dialogAddSo: false,
      text_colorSo: "text-h5 green--text text-center",
      titleSo: "green",
      messageSo: "green",
    };
  },
  methods: {
    async loadip_pricelist() {
      // const result = await api.getIPPriceListForInvSo();
      const result = await api.getInvTitleByDep(localStorage.getItem(server.DEPARTMENT_ID));
      this.inv_title_list = result.data;
      this.inv_title_list.push({ title: "Others", price: 0 });
    },
    async loadinv_titlelist() {
      const result = await api.getInvTitleByDep(localStorage.getItem(server.DEPARTMENT_ID));
  
      this.inv_title_list = result.data;
      this.inv_title_list.push({ title: "Others", price: 0 });
    },
    async onSelectHeaderChange(headercheckbox) {
      this.editedItemadd.unit = 0;
      this.editedItemadd.PricePerUnit = 0;
    },
    async onSelectmiscellaneous_disbursementsChange(inv_title) {
      this.inv_title_list.filter((item) => {
        if (item.title == inv_title) {
          this.editedItemadd.unit = 1;
          this.editedItemadd.PricePerUnit = item.price;
        }
      });
    },
    save() {
      
      
      if (this.editedItemadd.headercheckbox) {
        this.editedItemadd.unit = 0;
        this.editedItemadd.PricePerUnit = 0;
      } else {
      }
      if (this.customer_code == "") {
        this.$store.state.global_dialog_invoicefreetext_so = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "กรุณาเลือก Customer",
          "text-h5 red--text text-center"
        );
        return;
      }


      if (
        !this.editedItemadd.headercheckbox &&
        (this.editedItemadd.unit == undefined ||
          this.editedItemadd.unit == "0" ||
          this.editedItemadd.unit == 0)
      ) {
        // alert("กรุณากรอกจำนวน");
        this.$store.state.global_dialog_invoicefreetext_so = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "กรุณากรอกจำนวน Qty!!!",
          "text-h5 red--text text-center"
        );
        this.$refs["txtunit"].focus();
        return;
      }

      if (!this.editedItemadd.headercheckbox && !this.editedItemadd.unit) {
        // alert("กรุณากรอกจำนวน");
        this.$store.state.global_global_dialog_invoicefreetext_sodialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "กรุณากรอกจำนวน Qty!!!",
          "text-h5 red--text text-center"
        );
        this.$refs["txtunit"].focus();
        return;
      }

      if (
        !this.editedItemadd.headercheckbox &&
        (this.editedItemadd.PricePerUnit == undefined ||
          this.editedItemadd.PricePerUnit == "0" ||
          this.editedItemadd.PricePerUnit == 0)
      ) {
        // alert("กรุณากรอกจำนวนเงินต่อหน่วย");
        this.$store.state.global_dialog_invoicefreetext_so = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "กรุณากรอกจำนวน Price Per Unit!!!",
          "text-h5 red--text text-center"
        );
        this.$refs["txtPricePerUnit"].focus();
        return;
      }

      if (this.editedItemadd.PricePerUnit < 0) {
        this.$store.state.global_dialog_invoicefreetext_so = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "จำนวน Price Per Unit ต้องมากกว่า 0!!!",
          "text-h5 red--text text-center"
        );
        this.$refs["txtunit"].focus();
        return;
      }
      if (
       (this.datainv_h.dept_type == "IM" || this.datainv_h.dept_type == "IP") &&
        this.editedItemadd.inv_title !== "Others"
      ) {
        if (this.editedItemadd.inv_title == "") {
        this.$store.state.global_dialog_invoicefreetext_so = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "กรุณาเลือก select invoice title",
          "text-h5 red--text text-center"
        );
        return;
      }
        this.editedItemadd.description =
          this.editedItemadd.inv_title +
          (this.editedItemadd.description != "" ||
          this.editedItemadd.description != undefined ||
          this.editedItemadd.description != null
            ? this.editedItemadd.description
            : "");
      } else {
      }
      if (
        this.editedItemadd.inv_title == "Others" && 
        (this.editedItemadd.description == "" ||
        this.editedItemadd.description == null)
      ) {
        this.$store.state.global_dialog_invoicefreetext_so = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "กรุณากรอก Description/Service!!!",
          "text-h5 red--text text-center"
        );
        this.$refs["txtDescription"].focus();
        return;
      }

      // if (this.editedIndex > -1) {
      //     Object.assign(this.desserts[this.editedIndex], this.editedItemadd);
      // } else {
      
      // }
      if(this.desserts.length == 0){
        this.desserts.push(this.editedItemadd);
      }else{
        let getolddesserts = [];
          for(let i = 0; i < this.desserts.length; i++){
            if(this.editedItemadd.unit == 0 && this.editedItemadd.PricePerUnit == 0){
              if (stringcommatonumber(this.desserts[i].unit.toString())  > 0 && stringcommatonumber(this.desserts[i].PricePerUnit.toString())  > 0 ){
                getolddesserts = [
    ...this.desserts.slice(0, i),
    this.editedItemadd,
    ...this.desserts.slice(i)
];
this.desserts = getolddesserts;
break;
}
else{
  if(i == this.desserts.length-1){
    this.desserts.push(this.editedItemadd);
            break;
  }
 
}
        }else{
            this.desserts.push(this.editedItemadd);
            break;
        }
      }
      }

      this.sumtotal = 0;

      this.desserts.forEach((item, index) => {
        item.no = index + 1;
        item.unit = stringcommatonumber(item.unit.toString());
        item.PricePerUnit = stringcommatonumber(item.PricePerUnit.toString());
        item.amount = parseFloat(item.unit * item.PricePerUnit).toFixed(2);
        this.sumtotal += parseFloat(item.amount, 10);
        item.unit = tolocalestringnumber(item.unit);
        item.PricePerUnit = tolocalestringnumber(item.PricePerUnit);
        item.amount = tolocalestringnumber(item.amount);
      });
      this.sumtotal = this.sumtotal.toFixed(2);
      // this.sumtotal = tolocalestringnumber(this.sumtotal);
      this.$emit("inv_sotab_isheader", this.sumtotal);
      this.$emit("inv_sotab_tabledesserts", this.desserts);
      this.close();
      this.$refs["txtDescription"].focus();
      this.editedItemadd = Object.assign({}, this.defaultItem);
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItemadd = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async loaddataSO() {
      this.$showLoader();
      if (this.datainv_h.customerid != 0) {
        this.customer_code = this.datainv_h.customer_code;
        this.customer_id = this.datainv_h.customerid;
        this.customer_name = this.datainv_h.customer_name;
        this.saleorder_id = this.datainv_h.saleorder_id;
        this.currency = this.datainv_h.currency;
        this.invoice_address = this.datainv_h.customer_address;
        this.payment_term = this.datainv_h.customer_paymentterm;
        this.branch = this.datainv_h.branch;
        this.tax_id = this.datainv_h.customer_taxid;
        this.vat = this.datainv_h.customer_vat;
        this.vatCal = this.datainv_h.customer_vat;
        this.po_no = this.datainv_h.po_no;
        this.attention = this.datainv_h.attention;
        this.contact_position = this.datainv_h.contact_position;
        this.vat_type = this.datainv_h.vat_type;

        if (this.datainv_so.length > 0) {
          this.desserts = this.datainv_so;
          let testsumso = 0;
          this.desserts.forEach((item, index) => {
            item.no = index + 1;
            item.unit = parseFloat(item.unit);
            item.PricePerUnit = parseFloat(item.PricePerUnit);
            item.amount = parseFloat(item.amount);
            testsumso += parseFloat(item.amount);
          });
          this.$store.state.inv_so_total = testsumso;
          this.$emit("inv_sotab_isheader", testsumso);
          this.$emit("inv_sotab_tabledesserts", this.desserts);
        } else {
          let testsumso = 0;
          this.$emit("inv_sotab_isheader", testsumso);
          this.$emit("inv_sotab_tabledesserts", this.desserts);
        }
        this.$hideLoader();
      } else {
        const res_h = await api.getAllSOById(this.so_id);
        const res_d = await api.getAlldetailSOById(this.so_id);

        if (res_h.data.length > 0) {
          let dataso_h = res_h.data[0];
          this.customer_code = dataso_h.customer_code;
          this.customer_id = dataso_h.customer_id;
          this.customer_name = dataso_h.name;
          this.invoice_address = dataso_h.customer_inv_address;
          this.payment_term = dataso_h.payment_term;
          this.branch = dataso_h.branch;
          this.vat = dataso_h.vat;
          this.vatCal = dataso_h.sum_tax;
          this.tax_id = dataso_h.tax_id;
          this.po_no = dataso_h.po_no;
          this.attention = dataso_h.attention;
          this.contact_position = dataso_h.contact_position;
          this.vat_type = dataso_h.vat_type;
          this.currency = dataso_h.currency;
          this.saleorder_id = dataso_h.saleorder_id;
          // console.log(this.customer_id)
          // console.log(dataso_h)
        }
        let testsumso = 0;
        this.desserts = res_d.data;
        this.desserts.forEach((item, index) => {
          item.no = index + 1;
          testsumso += parseFloat(item.amount);
        });
        this.desserts.unshift({headercheckbox: true,
        inv_title: "",
        description: `TO OUR CHARGES for professional service rendered to ${this.customer_name} in connection with `,  
        no: 1,
        unit:  "0",
        amount:  "0",
        PricePerUnit:  "0",})
        this.$store.state.inv_so_total = testsumso;

        this.$emit(
          "inv_sotab_noheader",
          this.customer_id,
          this.invoice_address,
          this.tax_id,
          this.branch,
          this.payment_term,
          this.vat,
          this.vat_type,
          testsumso,
          res_h.data[0].id
        );
        this.$emit("inv_sotab_tabledesserts", this.desserts);
        this.$hideLoader();
      }
    },

    async openeditItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.dataeditItem.no = item.no;
      this.dataeditItem.description = item.description;
      this.dataeditItem.unit = item.unit;
      this.dataeditItem.item_um = item.item_um;
      this.dataeditItem.PricePerUnit = stringcommatonumber(item.PricePerUnit.toString());
      this.dataeditItem.amount = item.amount;
      this.dialogEdit = true;
    },
    async saveeditItem() {
      // alert(this.desserts[this.editedIndex].unit);
      this.desserts[
        this.editedIndex
      ].description = this.dataeditItem.description;
      this.desserts[this.editedIndex].PricePerUnit = this.dataeditItem.PricePerUnit;
      this.desserts[this.editedIndex].unit = this.dataeditItem.unit;
      this.desserts[this.editedIndex].amount = this.dataeditItem.amount;
      // let testsumso = 0;
      // this.desserts.forEach((item, index) => {
      //   item.no = index + 1;
      //   testsumso += parseFloat(item.amount);
      // });
      this.sumtotal = 0;
      this.desserts.forEach((item, index) => {
        item.no = index + 1;
        item.unit = stringcommatonumber(item.unit.toString());
        item.PricePerUnit = stringcommatonumber(item.PricePerUnit.toString());
        item.amount = parseFloat(item.unit * item.PricePerUnit).toFixed(2);
        this.sumtotal += parseFloat(item.amount, 10);
        item.unit = tolocalestringnumber(item.unit);
        item.PricePerUnit = tolocalestringnumber(item.PricePerUnit);
        item.amount = tolocalestringnumber(item.amount);
      });
      this.sumtotal = this.sumtotal.toFixed(2);
      this.$store.state.inv_so_total = this.sumtotal;
      this.$emit("inv_sotab_isheader", this.sumtotal);
      this.$emit("inv_sotab_tabledesserts", this.desserts);
      this.dialogEdit = false;
    },
    async closeeditItem() {
      this.dialogEdit = false;
    },
    async calserviceamount(item) {
      this.dataeditItem.amount = parseFloat(
        item.unit * this.dataeditItem.PricePerUnit
      );
    },
    async loadCustomer() {
      let result = [];
      result = await api.getAllCustomerListactive();

      this.customer = result.data;

      result.data.forEach((item) => {
        this.customer_list.push(item.name);
      });
      this.$hideLoader();
    },

    onSelectCustomerCodeChange(code) {
      var tmp = null;
      tmp = this.customer.filter((c) => c.cust_account.includes(code));
      this.customer_name = tmp[0].name;
      this.customer_address = tmp[0].address;
      this.invoice_address = tmp[0].address_invoice;
      this.payment_term = tmp[0].payment_term;
      this.customer_code = tmp[0].cust_account;
      this.branch = tmp[0].branch;
      this.tax_id = tmp[0].taxid;
      this.po_no = tmp[0].po_no;
      this.attention = tmp[0].attention;
      this.contact_position = tmp[0].contact_position;
      this.vat_type = tmp[0].vat_type;
      this.vat = tmp[0].vat;
      this.vatCal = tmp[0].vat;
    },
    onSelectCustomerChange(search_customer) {
      var tmp = null;
      tmp = this.customer.filter((c) => c.name.includes(search_customer));
      this.invoice_address = tmp[0].address_invoice;
      this.payment_term = tmp[0].payment_term;
      this.customer_code = tmp[0].cust_account;
      this.branch = tmp[0].branch;
      this.tax_id = tmp[0].taxid;
      this.po_no = tmp[0].po_no;
      this.attention = tmp[0].attention;
      this.contact_position = tmp[0].contact_position;
      this.vat_type = tmp[0].vat_type;
      this.vat = tmp[0].vat;
      this.vatCal = tmp[0].vat;
    },
    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.dialogDelete = true;
    },
    deleteItemConfirm(index) {
      this.desserts.splice(this.editedIndex, 1);
      let testsumso = 0;
      this.desserts.forEach((item, index) => {
        item.no = index + 1;
        testsumso += parseFloat(item.amount);
      });
      this.$store.state.inv_so_total = testsumso;
      this.$emit("inv_sotab_isheader", testsumso);
      this.$emit("inv_sotab_tabledesserts", this.desserts);
      this.dialogDelete = false;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    async onSelectpayment() {
      this.$emit("inv_sotab_paymentcus", this.payment_term);
      // alert(this.payment_term)
    },
    async onSelectvat() {
      this.$emit("inv_sotab_vatcus", this.vat);
      // alert(this.vat)
    },
    async onSelectpono() {
      this.$emit("inv_sotab_ponocus", this.po_no);
      // alert(this.po_no)
    },
    async onSelectattention() {
      this.$emit("inv_sotab_attentioncus", this.attention);
    },
    async onSelectcontact_position() {
      this.$emit("inv_sotab_contact_positioncus", this.contact_position);
    },
    async onSelectvat_type() {
      this.$emit("inv_sotab_vat_type", this.vat_type);
    },

    setupAlertDialog(status, titleSo, messageSo, text_colorSo) {
      this.titleSo = titleSo;
      this.messageSo = messageSo;
      this.dialogAddSo = status;
      this.text_colorSo = text_colorSo;
    },
  },

  beforeCreate() {
    // console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
};
